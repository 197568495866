import _ from 'lodash';
import reorder from './reorder';

const withNewItemIds = (column: any, itemIds: any) => ({
  id: column.id,
  title: column?.title,
  inputs: column?.inputs,
  itemIds,
});

const reorderSingleDrag = ({
  entities,
  selectedItemIds,
  source,
  destination,
}: any) => {
  if (source.droppableId === destination.droppableId) {
    const column = entities.columns[source.droppableId];
    const reordered = reorder(column.itemIds, source.index, destination.index);

    const updated = {
      ...entities,
      columns: {
        ...entities.columns,
        [column.id]: withNewItemIds(column, reordered),
      },
    };

    return {
      entities: updated,
      selectedItemIds,
    };
  }

  const home = entities.columns[source.droppableId];
  const foreign = entities.columns[destination.droppableId];
  const itemId = home.itemIds[source.index];
  const newHomeItemIds = [...home.itemIds];
  newHomeItemIds.splice(source.index, 1);

  // add to foreign column
  const newForeignItemIds = [...foreign.itemIds];
  newForeignItemIds.splice(destination.index, 0, itemId);

  const updated = {
    ...entities,
    columns: {
      ...entities.columns,
      [home.id]: withNewItemIds(home, newHomeItemIds),
      [foreign.id]: withNewItemIds(foreign, newForeignItemIds),
    },
  };

  return {
    entities: updated,
    selectedItemIds,
  };
};

const reorderMultiDrag = ({
  entities,
  selectedItemIds,
  source,
  destination,
}: any) => {
  const start = entities.columns[source.droppableId];
  const dragged = start.itemIds[source.index];
  const insertAtIndex = (() => {
    const destinationIndexOffset = selectedItemIds.reduce(
      (previous: any, current: any) => {
        if (current === dragged) {
          return previous;
        }

        const final = entities.columns[destination.droppableId];
        const column = getHomeColumn(entities, current);

        if (column !== final) {
          return previous;
        }

        const index = column.itemIds.indexOf(current);

        if (index >= destination.index) {
          return previous;
        }

        // the selected item is before the destination index
        // we need to account for this when inserting into the new location
        return previous + 1;
      },
      0
    );

    const result = destination.index - destinationIndexOffset;
    return result;
  })();

  // doing the ordering now as we are required to look up columns
  // and know original ordering
  const orderedSelectedItemIds = [...selectedItemIds];
  orderedSelectedItemIds.sort((a, b) => {
    // moving the dragged item to the top of the list
    if (a === dragged) {
      return -1;
    }
    if (b === dragged) {
      return 1;
    }

    // sorting by their natural indexes
    const columnForA = getHomeColumn(entities, a);
    const indexOfA = columnForA.itemIds.indexOf(a);
    const columnForB = getHomeColumn(entities, b);
    const indexOfB = columnForB.itemIds.indexOf(b);

    if (indexOfA !== indexOfB) {
      return indexOfA - indexOfB;
    }

    // sorting by their order in the selectedItemIds list
    return -1;
  });

  // we need to remove all of the selected items from their columns
  const withRemovedItems = entities.columnOrder.reduce(
    (previous: any, columnId: any) => {
      const column = entities.columns[columnId];

      // remove the id's of the items that are selected
      const remainingItemIds = column.itemIds.filter(
        (id: any) => !selectedItemIds.includes(id)
      );

      previous[column.id] = withNewItemIds(column, remainingItemIds);
      return previous;
    },
    entities.columns
  );

  const final = withRemovedItems[destination.droppableId];
  const withInserted = (() => {
    const base = [...final.itemIds];
    base.splice(insertAtIndex, 0, ...orderedSelectedItemIds);
    return base;
  })();

  // insert all selected items into final column
  const withAddedItems = {
    ...withRemovedItems,
    [final.id]: withNewItemIds(final, withInserted),
  };

  const updated = {
    ...entities,
    columns: withAddedItems,
  };

  return {
    entities: updated,
    selectedItemIds: orderedSelectedItemIds,
  };
};

export const mutliDragAwareReorder = (args: any) => {
  if (args.selectedItemIds.length > 1) {
    return reorderMultiDrag(args);
  }
  return reorderSingleDrag(args);
};

export const getHomeColumn = (entities: any, itemId: any) => {
  const columnId = entities.columnOrder.find((id: any) => {
    const column = entities.columns[id];
    return column.itemIds.includes(itemId);
  });

  // invariant(columnId, "Count not find column for item");

  return entities.columns[columnId];
};

export const multiSelectTo = (
  entities: any,
  selectedItemIds: any,
  newItemId: any
) => {
  // Nothing already selected

  if (!selectedItemIds.length) {
    return [newItemId];
  }

  const columnOfNew = getHomeColumn(entities, newItemId);
  const indexOfNew = columnOfNew.itemIds.indexOf(newItemId);

  const lastSelected = selectedItemIds[selectedItemIds.length - 1];
  const columnOfLast = getHomeColumn(entities, lastSelected);
  const indexOfLast = columnOfLast.itemIds.indexOf(lastSelected);

  // multi selecting to another column
  // select everything up to the index of the current item
  if (columnOfNew !== columnOfLast) {
    return columnOfNew.itemIds.slice(0, indexOfNew + 1);
  }

  // multi selecting in the same column
  // need to select everything between the last index and the current index inclusive

  // nothing to do here
  if (indexOfNew === indexOfLast) {
    return null;
  }

  const isSelectingForwards = indexOfNew > indexOfLast;
  const start = isSelectingForwards ? indexOfLast : indexOfNew;
  const end = isSelectingForwards ? indexOfNew : indexOfLast;

  const inBetween = columnOfNew.itemIds.slice(start, end + 1);

  // everything inbetween needs to have it's selection toggled.
  // with the exception of the start and end values which will always be selected

  const toAdd = inBetween.filter((itemId: any) => {
    // if already selected: then no need to select it again
    if (selectedItemIds.includes(itemId)) {
      return false;
    }
    return true;
  });

  const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
  const combined = [...selectedItemIds, ...sorted];

  return combined;
};

export const getItems = (entities: any, columnId: any) => {
  let items = entities.columns[columnId].itemIds.map(
    (itemId: any) => entities.items[itemId]
  );
  items = items.map((item: any) => {
    item.display = "flex";
    return item;
  });

  let hiddenItems = entities.columns[columnId].hiddenItemIds?.map(
    (itemId: any) => entities.items[itemId]
  );
  let array = [...items];
  if (hiddenItems) {
    hiddenItems = hiddenItems.map((item: any) => {
      item.display = "none";
      return item;
    });
    items = items.filter((item: any) => hiddenItems.some((i: any) => i.id !== item.id));
  }

  array = array.filter(function (element) {
    return element !== undefined;
  });

  return array;
}
