import React from "react";

interface IProps {
    width?: string;
    height?: string;
    strokeColor: string;
}

const IconVerticalLine = (props: IProps) => (
    <svg width={props.width} height={props.height} viewBox="0 0 2 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1V22" stroke={props.strokeColor} stroke-linecap="round" />
    </svg>
);

export default IconVerticalLine;