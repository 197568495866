import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import './InputEmail.scss';

interface Props {
  label?: string;
  name: string;
  placeholder?: string;
  value?: string;
  onChange: (value: string, isValid: boolean) => void;
} 

interface State {
  value: string;
  isValid: boolean;
}
export class InputEmail extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      value: this.props.value || '',
      isValid: false,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.checkField = this.checkField.bind(this);
  }

  checkField() {
    let regExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    this.setState(
      {
        isValid: regExp.test(this.state.value),
      },
      () => this.props.onChange(this.state.value, this.state.isValid)
    );
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value!
      },() => this.checkField());
    }
  }

handleOnChange(e: ChangeEvent<HTMLInputElement>) {
 
  this.setState({
    value: e.currentTarget.value,
  }, () => this.checkField());
}

public static defaultProps = {
  label: 'Adresse email',
  placeholder: 'Ex : bob@onf.fr',
};

  render() {
    const {
      label,
      name,
      placeholder
    } = this.props;
    return (
      <div className='input'>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            type='text'
            name={name}
            placeholder={placeholder}
            value={this.state.value}
            onChange={(e: any) => this.handleOnChange(e)}
          />
          {!this.state.isValid && this.state.value.length > 0 && (
            <Form.Text className='error'>
              Veuillez renseigner une adresse email valide.
            </Form.Text>
          )}
        </Form.Group>
      </div>
    );
  }
}
