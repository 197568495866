import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import EventHelper from "../../Shared/Utils/Helpers/EventHelper";
import { IColumnToggleProps } from "./SWInterface";
import _ from "lodash";


const render = (onColumnToggle: any, column: any, index: number, orphan: boolean, columnToggle: (column: any, toggle: any) => void) => {
    return <li key={column?.datafield} className="dropdown-menu-item">
        <button
            type="button"
            key={column.dataField}
            className={`btn btn-outline-success btn-sw-column-toggle p-0 ${orphan ? "" : "pl-3"} mb-3 ${column.toggle ? 'active' : ''}`}
            data-toggle="button"
            aria-pressed={column.toggle ? 'true' : 'false'}
            onClick={() => {
                columnToggle(column, !column.toggle);
                onColumnToggle(column.dataField);
            }}
        >
            <span>
                <FontAwesomeIcon icon={column.toggle ? faEye : faEyeSlash} className={column.toggle ? 'icon-active' : ''} /> {column?.text?.length > 0 ? column?.text : `Colonne n°${index + 1}`}
            </span>
        </button>
    </li>
};

const SWColumnToggle = forwardRef(({
    columns,
    toggles,
    show,
    rootBoundary,
    columnSelectorByGroupCategory,
    columnSelectorSearchable,
    onToggle,
    onColumnToggle,
    columnToggle,
    columnsToggle
}: IColumnToggleProps, ref: any) => {
    const [searchValue, setSearchValue] = useState("");
    const [columnsToDisplay, setColumnsToDisplay] = useState(columns);

    const colonnesParCategorie = _.groupBy(
        columnsToDisplay
            .filter((column: any) => !column?.alwaysHidden)
            .map((column: any) => ({
                ...column,
                toggle: toggles[column.dataField]
            })), "columnGroupCategory");

    const element = rootBoundary
        ? document.querySelector(rootBoundary)
        : document.querySelector('#datatable-container');

    const popperConfig: any = {
        strategy: 'fixed',
        placement: 'top',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 10],
                },
            },
            {
                name: 'flip',
                options: {
                    rootBoundary: element,
                },
            },
        ],
    };

    const onSearchHandler = (e: any) => {
        setSearchValue(e.target.value);
    };
    
    useEffect(() => {
        getOptions();
    }, [searchValue]);

    const getOptions = () => {
        if (!searchValue) {
            setColumnsToDisplay(columns);
        } else {
            if(!columnSelectorByGroupCategory) {
                const filteredColumns = columns.filter(column => _.deburr(column.text.toLowerCase()).indexOf(_.deburr(searchValue.toLowerCase())) >= 0);
                setColumnsToDisplay(filteredColumns);
            } else {
                const filteredColumns = columns.flatMap(column => {
                    if (column?.columnGroupCategory && _.deburr(column.columnGroupCategory.toLowerCase()).indexOf(_.deburr(searchValue.toLowerCase())) >= 0) {
                      return [column];
                    } else if (_.deburr(column.text.toLowerCase()).indexOf(_.deburr(searchValue.toLowerCase())) >= 0) {
                      return [{ ...column, columnGroupCategory: undefined }];
                    }
                    return [];
                  });
                setColumnsToDisplay(filteredColumns);
            }
        }
    };

    return (
        <Dropdown
            ref={ref}
            alignRight
            show={show}
            onToggle={(isOpen: boolean) => onToggle(isOpen)}
            onClick={(e: any) => {
                e.stopPropagation();
                if (!e?.currentTarget?.className?.includes('show')) {
                    onToggle(!show);
                }
            }}
            onKeyUp={EventHelper.stopPropagation}
        >
            <div className="dropdown">
                <Dropdown.Toggle variant="outline-success" id="dropdown-selection-colonnes">
                    Afficher/Cacher colonnes
                </Dropdown.Toggle>

                <Dropdown.Menu
                    bsPrefix="dropdown-menu"
                    rootCloseEvent="click"
                    flip
                    popperConfig={popperConfig}
                >
                    {columnSelectorSearchable && (
                        <div className="row search-box">
                            <input
                                className="socle-multiselect-dropdown-input"
                                onChange={onSearchHandler}
                                value={searchValue}
                                autoComplete="off"
                                autoFocus
                                placeholder="Filtrer"
                            />
                        </div>
                    )}
                    <div className="filter-dropdown">
                        <Dropdown.Header className="dropdown-selection-colonnes-header">
                            {!columnSelectorByGroupCategory ? (
                                columnsToDisplay
                                    .filter(column => !column?.alwaysHidden)
                                    .map(column => ({
                                        ...column,
                                        toggle: toggles[column.dataField]
                                    }))
                                    .map((column, index) => (
                                        <>
                                            <li key={column?.datafield} className="dropdown-menu-item">
                                                <button
                                                    type="button"
                                                    key={column.dataField}
                                                    className={`btn btn-outline-success btn-sw-column-toggle p-0 mb-3 ${column.toggle ? 'active' : ''}`}
                                                    data-toggle="button"
                                                    aria-pressed={column.toggle ? 'true' : 'false'}
                                                    onClick={() => {
                                                        columnToggle(column, !column.toggle);
                                                    }}
                                                >
                                                    <span>
                                                        <FontAwesomeIcon icon={column.toggle ? faEye : faEyeSlash} className={column.toggle ? 'icon-active' : ''} /> {column?.text?.length > 0 ? column?.text : `Colonne n°${index + 1}`}
                                                    </span>
                                                </button>
                                            </li>
                                        </>
                                    ))
                            ) : (
                                Object.entries(colonnesParCategorie).map(([key, value]) => {
                                    // Colonnes sans catégorie
                                    if (key === "undefined") {
                                        return value
                                            .map((column: any, index: number) => (
                                                render(onColumnToggle, column, index, true, columnToggle)
                                            ))
                                    }
                                    // Colonnes avec catégorie
                                    else {
                                        return <>
                                            <li key={key} className="dropdown-menu-item">
                                                <button
                                                    type="button"
                                                    key={key}
                                                    className={`btn btn-outline-success btn-sw-column-toggle p-0 mb-3 ${value.every((column: any) => column.toggle) ? 'active' : ''}`}
                                                    data-toggle="button"
                                                    aria-pressed={value.every((column: any) => column.toggle) ? 'true' : 'false'}
                                                    onClick={() => {
                                                        columnsToggle(value, !value.every((column: any) => column.toggle));
                                                        value.forEach(column => {
                                                            onColumnToggle(column.dataField);
                                                        });
                                                    }}
                                                >
                                                    <span>
                                                        <FontAwesomeIcon icon={value.every((column: any) => column.toggle) ? faEye : faEyeSlash} className={value.every((column: any) => column.toggle) ? 'icon-active' : ''} /> {key}
                                                    </span>
                                                </button>

                                            </li>
                                            {
                                                value.map((column: any, index: number) => (
                                                    render(onColumnToggle, column, index, false, columnToggle)
                                                ))
                                            }
                                        </>;
                                    }
                                })
                            )}
                        </Dropdown.Header>
                    </div>
                </Dropdown.Menu>
            </div>
        </Dropdown>
    );
});

export default SWColumnToggle;