import React, { forwardRef } from "react";
import { Dropdown } from "react-bootstrap";
import EventHelper from "../../Shared/Utils/Helpers/EventHelper";

const SWSizePerPage = forwardRef(({
    options,
    currSizePerPage,
    onSizePerPageChange,
    show,
    rootBoundary,
    onToggle,
}: any, ref: any) => {

    const element = rootBoundary
        ? document.querySelector(rootBoundary)
        : document.querySelector('#datatable-container');

    const popperConfig: any = {
        strategy: 'fixed',
        placement: 'top',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 10],
                },
            },
            {
                name: 'flip',
                options: {
                    rootBoundary: element,
                },
            },
        ],
    };

    return (
        <div className="size-per-page-container">
            <div className="texte-size-per-page">
                Nombre de lignes affichées
            </div>
            <Dropdown
                ref={ref}
                show={show}
                onToggle={(isOpen: boolean) => onToggle(isOpen)}
                onClick={(e: any) => {
                    e.stopPropagation();
                    if (!e?.currentTarget?.className?.includes('show')) {
                        onToggle(!show);
                    }
                }}
                onKeyUp={EventHelper.stopPropagation}
            >
                <div className="dropdown">
                    <Dropdown.Toggle variant="outline-success" id="dropdown-selection-taille-page">
                        {currSizePerPage}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        bsPrefix="dropdown-menu"
                        rootCloseEvent="click"
                        style={{ display: 'block', minWidth: '5rem' }}
                        flip
                        popperConfig={popperConfig}
                    >
                        <div className="filter-dropdown">
                            <Dropdown.Header>
                                {
                                    options.map((option: any, index: number) => (
                                        <li key={index} className="dropdown-menu-item">
                                            <button
                                                type="button"
                                                key={option.text}
                                                className={`btn btn-outline-success btn-sw-column-toggle p-0 mb-3 ${option.text === currSizePerPage ? 'active' : ''}`}
                                                data-toggle="button"
                                                aria-pressed={option.text === currSizePerPage ? 'true' : 'false'}
                                                onClick={() => {
                                                    onSizePerPageChange(option.page);
                                                    onToggle(!show);
                                                }}
                                            >
                                                <span className="pl-2">
                                                    {option.text}
                                                </span>
                                            </button>
                                        </li>
                                    ))
                                }
                            </Dropdown.Header>
                        </div>
                    </Dropdown.Menu>
                </div>
            </Dropdown>
        </div>
    );
});

export default SWSizePerPage;