import React from "react";

const IconGeneralSort = () => (
    <svg width="17" height="17" viewBox="0 0 9 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.93302 22.25C4.74057 22.5833 4.25944 22.5833 4.06699 22.25L0.60289 16.25C0.41044 15.9167 0.651002 15.5 1.0359 15.5L7.96411 15.5C8.34901 15.5 8.58957 15.9167 8.39712 16.25L4.93302 22.25Z" fill="#A6A59E"/>
        <path d="M4.06699 0.75C4.25944 0.416667 4.74057 0.416667 4.93302 0.75L8.39712 6.75C8.58957 7.08333 8.34901 7.5 7.96411 7.5H1.0359C0.651002 7.5 0.410439 7.08333 0.602889 6.75L4.06699 0.75Z" fill="#A6A59E"/>
    </svg>
);

export default IconGeneralSort;
