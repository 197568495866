export const grid = 12;
export const gridItem = 4;
export const borderRadius = 2;
export const primaryButton = 0;
export const keyCodes = {
    enter: 13,
    escape: 27,
    arrowDown: 40,
    arrowUp: 38,
    tab: 9,
};
export const size = 30;