import React from "react";

const IconAscSortString = () => (
    <svg width="17" height="17" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3227 8.60987L17.4526 11.4376H15.2336L18.9576 0.440308H21.5263L25.2336 11.4376H22.9023L22.0301 8.60987H18.3227ZM21.5908 6.99081L20.2336 2.542H20.1358L18.7786 6.99081H21.5908Z" fill="#009878"/>
        <path d="M4.71707 2.15628C4.71707 1.88278 4.82572 1.62048 5.01912 1.42708C5.21252 1.23368 5.47482 1.12503 5.74832 1.12503C6.02183 1.12503 6.28413 1.23368 6.47753 1.42708C6.67092 1.62048 6.77957 1.88278 6.77957 2.15628L6.77957 22.3543L9.1432 19.9887C9.23908 19.8928 9.35291 19.8167 9.47818 19.7648C9.60345 19.7129 9.73772 19.6862 9.87332 19.6862C10.0089 19.6862 10.1432 19.7129 10.2685 19.7648C10.3937 19.8167 10.5076 19.8928 10.6034 19.9887C10.6993 20.0845 10.7754 20.1984 10.8273 20.3236C10.8792 20.4489 10.9059 20.5832 10.9059 20.7188C10.9059 20.8544 10.8792 20.9886 10.8273 21.1139C10.7754 21.2392 10.6993 21.353 10.6034 21.4489L6.46401 25.5863C6.27039 25.7751 6.01001 25.8797 5.73958 25.8774C5.46916 25.8751 5.21061 25.766 5.02026 25.5739L0.895257 21.4489C0.701616 21.2555 0.592723 20.9932 0.59253 20.7195C0.592336 20.4459 0.70086 20.1833 0.894227 19.9897C1.0876 19.796 1.34997 19.6872 1.62362 19.687C1.89728 19.6868 2.15981 19.7953 2.35345 19.9887L4.71707 22.3543L4.71707 2.15628Z" fill="#84847A"/>
        <path d="M16.5 25.9972H24.6118V24.2091H19.1565V24.0977L24.4509 16.4252V15H16.6444V16.7882H21.8192V16.8996L16.5 24.5721V25.9972Z" fill="#84847A"/>
    </svg>
);

export default IconAscSortString;
