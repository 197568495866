import React, { Component } from 'react';
import './Version.scss';
import { VersionService } from './VersionService';
interface State {
  environnement: any;
  version: any;
}
interface SideValue {
  collapsed: any;
  showEnProd?: boolean;
  footerTitle?: JSX.Element | string;
  footerIcon?: JSX.Element;
}
export default class Version extends Component<SideValue, State> {
  public versionService: VersionService;
  public title = '';
  public versionInfo;
  constructor(props: SideValue) {
    super(props);
    this.state = { environnement: null, version: null };
    this.versionService = new VersionService();
    this.handlefindVersion = this.handlefindVersion.bind(this);
    this.title = process.env.REACT_APP_SITE_TITLE!;
  }
  componentDidMount() {
    this.handlefindVersion();
  }
  handlefindVersion() {
    this.versionService.getInfo().then((response: any) => {
      if (response.isSuccess()) {
        const data = response?.value?.data;
        this.setState({
          environnement: data?.environnement,
          version: data?.version,
        });
      }
    });
  }
  render() {
    return (
      <div className="version">
        {this.props.footerIcon && (
          <div className="logo-footer">{this.props.footerIcon}</div>
        )}
        <div className={`info-footer ${this.props.collapsed && 'collapsed'} `}>
          {this.title ? this.title : 'Non renseigné'}{' '}
          {this.state.version ? <span> - {this.state.version} </span> : ''}
          {((this.state?.environnement &&
            !this.state?.environnement?.toLowerCase()?.includes('prod')) ||
            this.props.showEnProd) && <div>({this.state.environnement})</div>}
          {this.props.footerTitle}
        </div>
      </div>
    );
  }
}
