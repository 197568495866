export default new (class PermissionManager {
  private name = 'user-permissions';
  private nameV2 = 'user-permissions-v2';

  setOrReplacePermissions(permissions: any): void {
    localStorage.setItem(this.name, JSON.stringify(permissions));
  }

  setOrReplacePermissionsByCodeStructureNonSignifiant(permissions: any): void {
    localStorage.setItem(this.nameV2, JSON.stringify(permissions));
  }

  getPermissions(): any {
    return JSON.parse(localStorage.getItem(this.name));
  }

  getPermissionsByCodeStructureNonSignifiant(): any {
    return JSON.parse(localStorage.getItem(this.nameV2));
  }

  hasPermission(codePermission: string, codeStructure?: number): boolean {
    const permissions = this.getPermissions();
    let hasPerm = false;
    if (!codeStructure) {
      hasPerm = permissions[codePermission] ? true : false;
    } else {
      if (permissions[codePermission]) {
        const codeStrSize = codeStructure.toString().length;
        for (let i = 0; i < permissions[codePermission].length; i++) {
          const code = permissions[codePermission][i];
          const codeSize = code.toString().length;
          if (code <= 0) {
            return true;
          }
          if (codeSize < codeStrSize) {
            hasPerm =
              code.toString() ===
              codeStructure.toString().substring(0, codeSize);
          }
          if (codeStrSize === codeSize) {
            hasPerm = code.toString() === codeStructure.toString();
          }
          if (hasPerm) return true;
        }
      }
    }
    return hasPerm;
  }

  hasPermissionByCodeStructureNonSignifiant(codePermission: string, codeStructureNonSignifiant?: string): boolean {
    const permissions = this.getPermissionsByCodeStructureNonSignifiant();
    let hasPerm = false;
    if (!codeStructureNonSignifiant) {
      hasPerm = permissions[codePermission] ? true : false;
    } else {
      if (permissions[codePermission]) {
        const codeStrSize = codeStructureNonSignifiant.length;
        for (let i = 0; i < permissions[codePermission].length; i++) {
          const code = permissions[codePermission][i].codeStructure;
          const codeSize = code.toString().length;
          if (code <= 0) {
            return true;
          }
          if (codeSize < codeStrSize) {
            hasPerm =
              code.toString() ===
              codeStructureNonSignifiant.substring(0, codeSize);
          }
          if (codeStrSize === codeSize) {
            hasPerm = code.toString() === codeStructureNonSignifiant;
          }
          if (hasPerm) return true;
        }
      }
    }
    return hasPerm;
  }

  private removeDuplicatesInArray(arr: Array<any>) {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it);
  }

  getCodeStructuresOfPermissions(
    codePermissions: Array<string>
  ): Array<number> {
    const permissions = this.getPermissions();
    let codeStructures: Array<number> = [];
    codePermissions.forEach((codePermission: string) => {
      if (this.hasPermission(codePermission)) {
        codeStructures.push(...permissions[codePermission]);
      }
    });
    return this.removeDuplicatesInArray(codeStructures);
  }

  getCodeStructuresNonSignifiantOfPermissions(
    codePermissions: Array<string>
  ): Array<string> {
    const permissions = this.getPermissionsByCodeStructureNonSignifiant();
    let codeStructures: Array<string> = [];
    codePermissions.forEach((codePermission: string) => {
      if (this.hasPermissionByCodeStructureNonSignifiant(codePermission)) {
        for (let i = 0; i < permissions[codePermission].length; i++) {
          const code = permissions[codePermission][i].codeStructure;
          codeStructures.push(code);
        }
      }
    });
    return this.removeDuplicatesInArray(codeStructures);
  }

  isStructureNationale(codeStructure: number): boolean {
    return codeStructure <= 0;
  }

  isStructureDT(codeStructure: number): boolean {
    return codeStructure.toString().length === 2 && codeStructure !== 92;
  }
})();
