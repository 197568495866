import React from "react";
import './Layout.scss';

export class Layout extends React.Component {
    render() {
        return (
            <div className="layout">
                {this.props.children}
            </div>
        )
    }
}
