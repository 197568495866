import { AbstractSocleService } from './AbstractSocleService';
import { HealthCheckError, HealthCheckResponse } from './../../Components/Health/HealthCheckInterface';

class HealthService extends AbstractSocleService {

    public async getHealth() {
        return this.httpService.get<HealthCheckResponse, HealthCheckError>(this.getEndpointPath() + "/health");
    }

    getEndpointPath(): string {
        return '/actuator';
    }
}

export default new HealthService();