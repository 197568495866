import React from "react";

interface IProps {
    checked: boolean;
    strokeColor: string;
    width?: string;
    height?: string;
}

const IconCheckBox = (props: IProps) => (
    <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 100 100">
        {props.checked ?
            <>
                <rect width="100" height="100" fill="#408b7a" />
                <path d="M40.62,71.32A3,3,0,0,0,45,67.06L29.15,52.77A3,3,0,0,0,24.82,57Z" fill="#fff" fillRule="evenodd" />
                <path d="M79.59,30.76a3,3,0,0,0-4.2.17L40.69,67.31a3,3,0,0,0,4.48,4.1L79.87,35A3,3,0,0,0,79.59,30.76Z" fill="#fff" />
            </>
            :
            <rect width="100" height="100" fill="#fff" stroke={props.strokeColor} strokeOpacity="1" strokeWidth="10" />
        }
    </svg>
);

export default IconCheckBox;
