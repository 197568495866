import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Dropdown } from "react-bootstrap";
import SWActionsButtons from "./SWActionsButtons";
import { IFilterProps } from "./SWInterface";

const SWDefaultFilter = forwardRef(
  ({ column, onFilter, onToggle, show }: IFilterProps, ref: any) => {
    const [input, setInput] = useState<string>('');

    useEffect(() => {
      if (column?.activeFilters) {
        setInput(column?.activeFilters);
      }
    }, [column?.activeFilters]);

    const inputRef = useCallback(
      (node) => {
        if (node && show[column.dataField]) {
          node?.focus();
        }
      },
      [show[column.dataField]]
    );

    useEffect(() => {
      if (column?.filter?.props?.getFilter) {
        column.filter.props.getFilter((filterVal: any) => {
          onFilter(filterVal);
        });
      }
    }, [column]);

    useImperativeHandle(ref, () => ({
      onReset: () => {
        onReset();
      },
    }));

    const onFilterHandler = () => {
      onFilter(input);
    };

    const onSubmit = (e: any) => {
      e.preventDefault();
      onToggle(column.dataField, false);
      onFilterHandler();
    };

    const onReset = () => {
      setInput('');
      onToggle(column.dataField, false);
      onFilter(null);
    };

    return (
      <form onSubmit={onSubmit} noValidate className="p-2">
        <input
          ref={inputRef}
          className="table-input"
          type="text"
          value={input}
          placeholder={`Rechercher par ${column.text}`}
          onChange={(e: any) => setInput(e.target.value)}
          autoComplete="off"
          autoFocus
        />

        <Dropdown.Divider />

        <SWActionsButtons onSubmit={onSubmit} onReset={onReset} />
      </form>
    );
  }
);

export default SWDefaultFilter;
