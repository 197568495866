import { LoginResult } from '../models/LoginResult';
import { LoginModel } from '../models/LoginModel';
import { PostPutModel } from '../models/PostPutModel';
import TokenManager from './TokenManager';

import { GlobalConfig as GC } from '../common/GlobalConfig';
import { ErrorModel } from '../models/ErrorModel';
import { SafeResult } from '../models/SafeResult';
import { StatusErrorType } from '../models/StatusErrorType';
import { AbstractSocleService } from './AbstractSocleService';
export class LoginService extends AbstractSocleService{  
  private endPoint: string="/auth";
  constructor(){
    super();
  }

  async login<
    loginResultType = LoginResult,
    errorType extends StatusErrorType = ErrorModel
  >(
    loginModel: LoginModel,
    extractTokenCb: CallableFunction = (data: LoginResult) => {
      return data.accessToken;
    }
  ): Promise<SafeResult<loginResultType, errorType>> {
    GC.logger.log('Logging in ' + loginModel.username, 'AuthService');
    const response = await this.httpService.post<
      loginResultType,
      errorType
    >(this.endPoint, new PostPutModel(loginModel, {}));

    if (response.isSuccess()) {
      return new SafeResult<loginResultType, errorType>(
        'Logged in successfully.',
        <loginResultType>response.value.data
      );
    } else {
      GC.logger.log('Failed to login ' + loginModel.username, 'AuthService');
      return new SafeResult<loginResultType, errorType>(
        'Failed to login',
        undefined,
        <errorType>response.error.data
      );
    }
  }

}
