import React from 'react';
import './Message.scss';


interface Props {
  type: 'error' | 'warning' | 'info';
}
export class Message extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    
    return (
      <div className={this.props.type +'-message'}>
        {this.props.type === 'error' && <p className={"title-" + this.props.type + "-message"}>Erreur</p>}
        {this.props.type === 'warning' && <p className={"title-" + this.props.type + "-message"}>Avertissement</p>}
        {this.props.type === 'info' && <p className={"title-" + this.props.type + "-message"}>Information</p>}
        <p className={"content-" + this.props.type + "-message"}>{this.props.children}</p>
      </div>
    );
  }
}
