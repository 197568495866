import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import './InputCodePostal.scss';

interface Props {
  label?: string;
  name: string;
  placeholder?: string;
  value?: string
  onChange: (value: string, isValid: boolean) => void;
}

interface State {
  value: string;
  isValid: boolean;
}

export class InputCodePostal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: this.props.value ||'',
      isValid: false,
    };
    this.checkField = this.checkField.bind(this);
  }

  checkField() {
    let regExp =  new RegExp(/^(([0-8][0-9])|(9[0-8]))[0-9]{3}$/);
    this.setState(
      {
        isValid: regExp.test(this.state.value),
      },
      () => this.props.onChange(this.state.value, this.state.isValid)
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value!
      },() => this.checkField());
    }
  }

  handleOnChange(e: ChangeEvent<HTMLInputElement>) {
  
    this.setState(
      {
        value: e.currentTarget.value,
      },
      () => this.checkField()
    );
  }

  public static defaultProps = {
    label: 'Code Postal',
    placeholder: 'Ex : 75010',
  };

  render() {
    const { label, name, placeholder, value } = this.props;

    return (
      <div className='input'>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            type='string'
            maxLength={5}
            name={name}
            placeholder={placeholder}
            value={this.state.value}
            onChange={(e: any) => this.handleOnChange(e)}
          />
          {!this.state.isValid && this.state.value.length > 0 && (
            <Form.Text className='error'>
              Veuillez renseigner un Code Postal valide.
            </Form.Text>
          )}
        </Form.Group>
      </div>
    );
  }
}
