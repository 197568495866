import React, { useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import EventHelper from '../../Shared/Utils/Helpers/EventHelper';
import { ISWFormatterProps } from './SWInterface';
import SWToggle from './SWToggle';
import { InfoBulle } from '../../Shared/UI/InfoBulle';

const SWHeaderFormatter = ({
  column,
  components: { sortElement, filterElement },
  filter,
  show,
  rootBoundary,
  onToggle,
  overrideFilterIcon,
  smallSpace,
}: ISWFormatterProps) => {
  const ref = useRef(null);

  const element = rootBoundary
    ? document.querySelector(rootBoundary)
    : document.querySelector('#datatable-container');

  const popperonfig: any = {
    strategy: 'fixed',
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'flip',
        options: {
          rootBoundary: element,
        },
      },
    ],
  };

  return (
    <div className="table-filter-column" ref={ref}>
      <span className="table-column-title">
        <div className="table-column-sorters">
          <span className="table-column-title">
            {column.infoBulleText || column.headerIcon ? (
              <div className='info-bulle-column'>
                {column.infoBulleText && (
                  <InfoBulle info={column.infoBulleText} icon={column.headerIcon} />
                )}
                {!column.infoBulleText && column.headerIcon && 
                  column.headerIcon
                }
              </div>
            ) : null}
            {column.text}
          </span>
          <span className="table-column-sorter">{sortElement}</span>
        </div>
      </span>
      {filter && (
        <Dropdown
          bsPrefix={`table-filter-trigger ${overrideFilterIcon ? "icon-overrided" : "icon-not-overrided"}${column?.isFilter ? " bg-filter" : ''}${smallSpace ? " icon-sm justify-content-center" : ''}`}
          style={{ height: ref?.current?.clientHeight || 45 }} // On récupère la taille de la partie de gauche pour mettre la même taille
          alignRight
          show={show[column.dataField]}
          onToggle={(isOpen: boolean) => onToggle(column?.dataField, isOpen)}
          onClick={(e: any) => {
            e.stopPropagation();
            if (!e?.currentTarget?.className?.includes('show')) {
              onToggle(column.dataField, !show[column.dataField]);
            }
          }}
          onKeyUp={EventHelper.stopPropagation}
        >
          <div className="table-dropdown">
            <Dropdown.Toggle as={SWToggle} id="dropdown-basic" overrideFilterIcon={overrideFilterIcon} isFilter={column?.isFilter} />

            <Dropdown.Menu
              bsPrefix="table-dropdown-menu"
              rootCloseEvent="click"
              flip
              popperConfig={popperonfig}
            >
              <div className="table-filter-dropdown">
                <Dropdown.Header className="text-center m-0 p-0">
                  {filterElement}
                </Dropdown.Header>
              </div>
            </Dropdown.Menu>
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default React.memo(SWHeaderFormatter);
