import React from "react";

const IconDescSortNumber = () => (
    <svg width="17" height="17" viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.96682 1.4671C4.96682 1.17702 5.08205 0.898821 5.28717 0.693703C5.49229 0.488585 5.77049 0.373352 6.06057 0.373352C6.35065 0.373352 6.62885 0.488585 6.83397 0.693703C7.03908 0.898821 7.15432 1.17702 7.15432 1.4671L7.15432 20.7018L9.66119 18.1927C9.76288 18.091 9.88361 18.0104 10.0165 17.9553C10.1493 17.9003 10.2918 17.872 10.4356 17.872C10.5794 17.872 10.7218 17.9003 10.8547 17.9553C10.9875 18.0104 11.1082 18.091 11.2099 18.1927C11.3116 18.2944 11.3923 18.4151 11.4473 18.548C11.5024 18.6809 11.5307 18.8233 11.5307 18.9671C11.5307 19.1109 11.5024 19.2533 11.4473 19.3862C11.3923 19.5191 11.3116 19.6398 11.2099 19.7415L6.81963 24.1296C6.614 24.3293 6.33795 24.4399 6.05131 24.4375C5.76467 24.435 5.49055 24.3197 5.28838 24.1165L0.913378 19.7415C0.811685 19.6399 0.730991 19.5193 0.6759 19.3866C0.62081 19.2539 0.592402 19.1116 0.592301 18.9679C0.592199 18.8242 0.620405 18.6818 0.675308 18.549C0.730211 18.4162 0.810736 18.2955 0.912285 18.1938C1.01383 18.0921 1.13442 18.0114 1.26715 17.9563C1.39989 17.9013 1.54217 17.8728 1.68589 17.8727C1.8296 17.8726 1.97192 17.9008 2.10474 17.9557C2.23755 18.0107 2.35825 18.0912 2.45994 18.1927L4.96682 20.7018L4.96682 1.4671Z" fill="#84847A"/>
        <path d="M15.99 1.78911C15.7849 1.99423 15.6697 2.27243 15.6697 2.56251C15.6697 2.85259 15.7849 3.13079 15.99 3.33591C16.1951 3.54103 16.4733 3.65626 16.7634 3.65626L32.0759 3.65626C32.366 3.65626 32.6442 3.54103 32.8493 3.33591C33.0545 3.13079 33.1697 2.85259 33.1697 2.56251C33.1697 2.27243 33.0545 1.99423 32.8493 1.78912C32.6442 1.584 32.366 1.46876 32.0759 1.46876L16.7634 1.46876C16.4733 1.46876 16.1951 1.584 15.99 1.78911Z" fill="#009878"/>
        <path d="M15.99 8.35162C16.1951 8.1465 16.4733 8.03127 16.7634 8.03127L27.7009 8.03127C27.991 8.03127 28.2692 8.1465 28.4743 8.35162C28.6795 8.55674 28.7947 8.83494 28.7947 9.12502C28.7947 9.4151 28.6795 9.6933 28.4743 9.89842C28.2692 10.1035 27.991 10.2188 27.7009 10.2188L16.7634 10.2188C16.4733 10.2188 16.1951 10.1035 15.99 9.89842C15.7849 9.6933 15.6697 9.4151 15.6697 9.12502C15.6697 8.83494 15.7849 8.55674 15.99 8.35162Z" fill="#009878"/>
        <path d="M15.99 14.9141C16.1951 14.709 16.4733 14.5938 16.7634 14.5938H23.3259C23.616 14.5938 23.8942 14.709 24.0993 14.9141C24.3044 15.1192 24.4197 15.3974 24.4197 15.6875C24.4197 15.9776 24.3044 16.2558 24.0993 16.4609C23.8942 16.666 23.616 16.7813 23.3259 16.7813H16.7634C16.4733 16.7813 16.1951 16.666 15.99 16.4609C15.7849 16.2558 15.6697 15.9776 15.6697 15.6875C15.6697 15.3974 15.7849 15.1192 15.99 14.9141Z" fill="#009878"/>
        <path d="M15.99 21.4766C16.1951 21.2715 16.4733 21.1563 16.7634 21.1563H18.9509C19.241 21.1563 19.5192 21.2715 19.7243 21.4766C19.9294 21.6817 20.0447 21.9599 20.0447 22.25C20.0447 22.5401 19.9294 22.8183 19.7243 23.0234C19.5192 23.2285 19.241 23.3438 18.9509 23.3438H16.7634C16.4733 23.3438 16.1951 23.2285 15.99 23.0234C15.7849 22.8183 15.6697 22.5401 15.6697 22.25C15.6697 21.9599 15.7849 21.6817 15.99 21.4766Z" fill="#009878"/>
    </svg>

);

export default IconDescSortNumber;
