import React from "react";

import "../Layout.scss"
import "./Content.scss"

export class Content extends React.Component{
    render() {
        return (
            <div className="content" id="content-div">
                {this.props.children}
            </div>
        )
    }
}