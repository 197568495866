import * as React from 'react';

const IconToggleRight = (props: any) => (
  <svg
    width={35}
    height={46}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 .5h24.5v45H10A9.5 9.5 0 0 1 .5 36V10A9.5 9.5 0 0 1 10 .5Z"
      fill="#fff"
      fillOpacity={0.2}
      stroke="#fff"
    />
    <mask
      id="a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={10}
      y={13}
      width={9}
      height={18}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 30.182V13.818h-9v16.364h9Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 14.565a.73.73 0 0 0 .228.524l6.928 6.913-6.928 6.912a.73.73 0 0 0-.224.538c.008.41.36.737.785.73a.785.785 0 0 0 .548-.234l7.446-7.429a.725.725 0 0 0 0-1.034l-7.446-7.429a.792.792 0 0 0-1.09-.04.732.732 0 0 0-.247.55"
        fill="#fff"
      />
    </g>
    <mask
      id="b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={17}
      y={13}
      width={10}
      height={18}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5 30.182V13.818h-9v16.364h9Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#b)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 14.565a.73.73 0 0 0 .228.524l6.928 6.913-6.928 6.912a.73.73 0 0 0-.224.538c.008.41.36.737.785.73a.785.785 0 0 0 .548-.234l7.446-7.429a.725.725 0 0 0 0-1.034l-7.446-7.429a.792.792 0 0 0-1.09-.04.732.732 0 0 0-.247.55"
        fill="#fff"
      />
    </g>
  </svg>
);

export default IconToggleRight;
