import React, { useEffect, useState } from 'react';
import HealthService from '../../Http/services/HealthService';
import SocleDataTable from '../../Components/SocleDataTable/SocleDataTable';
import HealthDetailsModale from './HealthDetailModal';
import './Health.scss';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISocleColumDataTable } from '../SocleDataTable/SWInterface';
import { ComponentRow, Component } from './../../Components/Health/HealthCheckInterface';
import { SocleButtonPrimary } from './../../Shared/UI/Button/ButtonPrimary/ButtonPrimary';

enum HealthStatus {
    UP = 'UP',
    DOWN = 'DOWN'
}

export const Health = () => {
    const [healthData, setHealthData] = useState<ComponentRow[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<ComponentRow>(null);

    const columnsHealthTable: ISocleColumDataTable[] = [
        {
            dataField: 'name',
            text: 'Service Name',
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (_cell, row) => (
                <div className={`${row.status === HealthStatus.UP ? 'up-status' : 'down-status'}`}>{row.status}</div>
            ),
        },
        {
            dataField: 'details',
            text: 'Details',
            formatter: (_cell, row) => (
                (row?.details && <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} onClick={() => handleDetails(row)} />)
            ),
        },
    ];

    const handleDetails = (row: ComponentRow) => {
        setModalContent(row);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const formateData = (data: { [key: string]: Component }) => {
        return Object.entries(data).map(
            ([name, component]: [string, any]) => ({
                name,
                status: component?.status || null,
                details: component?.details ? Object.entries(component?.details || {}).map(
                    ([detailName, detailValue]: [string, any]) => ({
                        name: detailName,
                        value: JSON.stringify(detailValue),
                    })
                ) : null,
            })
        );
    };

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setLoading(true);
        HealthService.getHealth()
            .then((res) => {
                if (res.value?.data.components) {
                    setHealthData(formateData(res.value?.data.components));
                } else if (res.error?.data.components) {
                    setHealthData(formateData(res.error?.data.components));
                }
            })
            .catch((err) => {
                console.error("error : ", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const hidePagination = (): JSX.Element => {
        return <div />;
    };

    return (
        <>
            <HealthDetailsModale
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                modalContent={modalContent}
                loading={loading}
            />
            <div className='health-wrapper'>
                <div className='health-header'>
                    <div className='health-title'>Health Checks</div>
                    <SocleButtonPrimary id="button-refresh" onClick={() => getData()} isLoading={loading}>
                        Refresh
                    </SocleButtonPrimary>
                </div>
                <div className='health-status'>
                    <div>Statut de l'application : </div>
                    <div className={`${healthData.every((component) => component.status === HealthStatus.UP) ? 'up-status' : 'down-status'}`}>
                        {healthData.every((component) => component.status === HealthStatus.UP) ? HealthStatus.UP : HealthStatus.DOWN}
                    </div>
                </div>
                <SocleDataTable
                    keyField='name'
                    data={healthData}
                    columns={columnsHealthTable}
                    hideSearchBar
                    hideColumnSelector
                    paginationOptions={{
                        hideSizePerPage: true,
                        paginationTotalRenderer: hidePagination,
                        pageListRenderer: hidePagination,
                        sizePerPage: Number.MAX_SAFE_INTEGER,
                    }}
                    loadingOptions={{
                        active: loading
                    }}
                />
            </div>
        </>
    );
};
