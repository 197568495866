import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Form } from 'react-bootstrap';
import './Fieldset.scss';

interface Props {
  switch: boolean;
  label: string;
  checked?: boolean;
  name: string;
  onCheck?: (checked: boolean) => void; 
}

interface State {
  checked: boolean;
}

export class Fieldset extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      checked: this.props.checked || false,
    };
    this.handleCheck = this.handleCheck.bind(this);
  }

handleCheck() {
  this.setState({
    checked: !this.state.checked
  }, () => this.props.onCheck(this.state.checked));
}

  render() {
    return (
      <div className="fieldset">
        <fieldset 
          disabled={this.props.switch && !this.state.checked}>
          <legend className='legend'>
            {this.props.switch && (
              <Form.Group>
                <Form.Check
                  checked={this.state.checked}
                  type='switch'
                  id='custom-switch'
                  name={this.props.name}
                  label={this.props.label}
                  onChange={() => this.handleCheck()}
                />
              </Form.Group>
            )}

            {!this.props.switch && (
              <div className="toggler" onClick={() =>
                this.setState({ checked: !this.state.checked })
              }>{!this.state.checked && <span className="toggle-icon"><FontAwesomeIcon icon={faPlus}/></span>} {this.state.checked && <span className="toggle-icon"><FontAwesomeIcon icon={faMinus}/></span>} {this.props.label}</div>
            )}
          </legend>
          {!this.props.switch && this.state.checked && this.props.children}
          {this.props.switch && this.props.children}
        </fieldset>
      </div>
    );
  }
}
