class ToasterService {
  private pushCb?: (model: ToastModel) => void;
  setOnPushCallback(callback: (model: ToastModel) => void) {
      this.pushCb = callback
  }
  push(model: ToastModel) {
      if(this.pushCb != undefined) {
          this.pushCb(model)
      }
  }
}

enum ToastType {
  Info = "info",
  Warning = "warning",
  Error = "error",
  Success = "success",
}
class ToastModel {
  constructor(
    public type: ToastType,
    public content: React.ReactNode,
    public timeout: number,
    public dateTime: boolean
  ) {}
}
export {ToastType, ToastModel};
export default new ToasterService();