import React from "react";
import './CheckButton.scss'

type IProps = Partial<{
    id: string;
    name: string;
    value: string | number | string[];
    onClick: React.MouseEventHandler<HTMLInputElement>;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    checked: boolean;
    defaultChecked: boolean;
    disabled: boolean;
    label: string;
}>;

const SocleCheckButton = ({ name, id, value, onClick, onChange, checked, label, disabled, defaultChecked }: IProps) => {
    return (
        <label htmlFor={id} className="socle-check-button">
            <input
                className="socle-check-input"
                type="checkbox"
                name={name}
                id={id}
                value={value}
                onClick={onClick}
                onChange={onChange}
                defaultChecked={defaultChecked}
                checked={checked}
                disabled={disabled}
            />
            <span className="socle-custom-check" aria-disabled={disabled} />
            <span className="socle-custom-check-label" aria-disabled={disabled}>{label}</span>
        </label>
    )
}

export default SocleCheckButton;