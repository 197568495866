import './SideBar.scss';
import { NavLink } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from 'react-pro-sidebar';
import React, { useState } from 'react';
import Version from '../Version/Version';
import IconToggleRight from '../Svg/IconToggleRight';
import IconToggleLeft from '../Svg/IconToggleLeft';

interface IMenu {
  title: string;
  icon?: any;
  link: string;
  subMenu?: IMenu[];
  defaultOpen?: boolean;
  externalLink?: boolean;
  openNewTab?: boolean;
}

interface Props {
  menu: IMenu[];
  info?: string;
  showEnProd?: boolean;
  footerTitle?: JSX.Element | string;
  footerIcon?: JSX.Element;
}
const SideBar = ({ menu, showEnProd, footerIcon, footerTitle }: Props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = (e: any) => {
    if (e?.preventDefault) e?.preventDefault();
    if (e?.stopPropagation) e?.stopPropagation();
    const elements: any = document.getElementsByClassName('popper-element');
    if (collapsed) {
      for (let item of elements) {
        item?.classList?.add('d-none');
      }
    }

    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value: boolean) => {
    setToggled(value);
  };

  const renderMenuItem = (item: IMenu, index: number) => (
    <MenuItem icon={item.icon} key={index}>
      {item.title}
    </MenuItem>
  );

  return (
    <ProSidebar
      toggled={toggled}
      onToggle={handleToggleSidebar}
      collapsed={collapsed}
    >
      <SidebarHeader>
        <div className="btn-toggle" onClick={handleCollapsedChange}>
          {collapsed ? <IconToggleRight /> : <IconToggleLeft />}
        </div>
      </SidebarHeader>
      <SidebarContent>
        {menu.map((m, index) => {
          return (
            <Menu key={index}>
              {Array.isArray(m.subMenu) ? (
                <SubMenu
                  title={m.title}
                  icon={m.icon}
                  defaultOpen={m.defaultOpen}
                >
                  {m.subMenu.map((sub, index0) => (
                    <div key={index0}>
                      {Array.isArray(sub.subMenu) ? (
                        <SubMenu
                          title={sub.title}
                          icon={sub.icon}
                          defaultOpen={m.defaultOpen}
                        >
                          {sub.subMenu.map((sub1, index1) => (
                            <div key={index1}>
                              {Array.isArray(sub1.subMenu) ? (
                                <SubMenu
                                  title={sub1.title}
                                  icon={sub1.icon}
                                  defaultOpen={m.defaultOpen}
                                >
                                  {sub1.subMenu.map((sub2, index2) => (
                                    sub2.externalLink ? (
                                      <a key={index2} href={sub2.link} target={sub2.openNewTab ? '_blank' : undefined}>
                                        {renderMenuItem(sub2, index2)}
                                      </a>
                                    ) : (
                                      <NavLink key={index2} to={sub2.link} target={sub2.openNewTab ? '_blank' : undefined}>
                                        {renderMenuItem(sub2, index2)}
                                      </NavLink>
                                    )
                                  ))}
                                </SubMenu>
                              ) : (
                                sub1.externalLink ? (
                                  <a key={index1} href={sub1.link} target={sub1.openNewTab ? '_blank' : undefined}>
                                    {renderMenuItem(sub1, index1)}
                                  </a>
                                ) : (
                                  <NavLink key={index1} to={sub1.link} target={sub1.openNewTab ? '_blank' : undefined}>
                                    {renderMenuItem(sub1, index1)}
                                  </NavLink>
                                )
                              )}
                            </div>
                          ))}
                        </SubMenu>
                      ) : (
                        sub.externalLink ? (
                          <a key={index0} href={sub.link} target={sub.openNewTab ? '_blank' : undefined}>
                            {renderMenuItem(sub, index0)}
                          </a>
                        ) : (
                          <NavLink key={index0} to={sub.link} target={sub.openNewTab ? '_blank' : undefined}>
                            {renderMenuItem(sub, index0)}
                          </NavLink>
                        )
                      )}
                    </div>
                  ))}
                </SubMenu>
              ) : (
                m.externalLink ? (
                  <a key={index} href={m.link} target={m.openNewTab ? '_blank' : undefined}>
                    {renderMenuItem(m, index)}
                  </a>
                ) : (
                  <NavLink key={index} to={m.link} target={m.openNewTab ? '_blank' : undefined}>
                    {renderMenuItem(m, index)}
                  </NavLink>
                )
              )}
            </Menu>
          );
        })}
      </SidebarContent>
      <SidebarFooter>
        {
          <Version
            collapsed={collapsed}
            showEnProd={showEnProd}
            footerIcon={footerIcon}
            footerTitle={footerTitle}
          />
        }
      </SidebarFooter>
    </ProSidebar>
  );
};

export default React.memo(SideBar);
