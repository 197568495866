import React from "react";

const IconDoubleArrowRight = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        fill="none"
        viewBox="0 0 35 35"
        {...props}
    >
        <rect x="1" y="1" width="33" height="33" rx="16.5" fill="white" stroke="#408B7A" strokeWidth="2" />
        <mask id="mask0_1648_467213" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="13" y="12" width="6" height="12">
            <path fillRule="evenodd" clipRule="evenodd" d="M19 24L19 12L13 12L13 24L19 24Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1648_467213)">
            <path fillRule="evenodd" clipRule="evenodd" d="M13 12.5478C13.0006 12.6924 13.0554 12.8305 13.1522 12.9322L17.7707 18.0014L13.1522 23.0704C13.0541 23.1749 13.0001 23.317 13.0026 23.4645C13.0077 23.7657 13.2421 24.0054 13.526 23.9999C13.6646 23.9973 13.7963 23.9353 13.8913 23.8283L18.8554 18.3806C19.0482 18.1691 19.0482 17.8336 18.8554 17.6221L13.8913 12.1745C13.6981 11.9537 13.3728 11.9407 13.1645 12.1454C13.059 12.2493 12.9994 12.3952 13 12.5478" fill="#2F2F2F" />
        </g>
        <mask id="mask1_1648_467213" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="18" y="12" width="6" height="12">
            <path fillRule="evenodd" clipRule="evenodd" d="M24 24L24 12L18 12L18 24L24 24Z" fill="white" />
        </mask>
        <g mask="url(#mask1_1648_467213)">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 12.5478C18.0006 12.6924 18.0554 12.8305 18.1522 12.9322L22.7707 18.0014L18.1522 23.0704C18.0541 23.1749 18.0001 23.317 18.0026 23.4645C18.0077 23.7657 18.2421 24.0054 18.526 23.9999C18.6646 23.9973 18.7963 23.9353 18.8913 23.8283L23.8554 18.3806C24.0482 18.1691 24.0482 17.8336 23.8554 17.6221L18.8913 12.1745C18.6981 11.9537 18.3728 11.9407 18.1645 12.1454C18.059 12.2493 17.9994 12.3952 18 12.5478" fill="#2F2F2F" />
        </g>
    </svg>
);

export default IconDoubleArrowRight;
