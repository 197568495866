import { SafeResult } from "../models/SafeResult";
import { AbstractSocleService } from "./AbstractSocleService";
import { PostPutModel } from '../models/PostPutModel';

//Socle Http Curd service
export abstract class AbstractCrudService extends AbstractSocleService {

    
    public findById(id:any): Promise<SafeResult<any, any>> {
        return this.httpService.get<any>(this.getEndpointPath().concat("/").concat(id));
    };

    public create(model: PostPutModel): Promise<SafeResult<any, any>> {
        return this.httpService
            .post<any>(this.getEndpointPath(), model);

    };
    public update(model: PostPutModel,id?:any): Promise<SafeResult<any, any>> {
        let endpoint=id?this.getEndpointPath().concat("/").concat(id):this.getEndpointPath();
        return this.httpService.put<any>(endpoint, model);
    }

    public deleteById(id:any): Promise<SafeResult<any, any>> {
        return this.httpService.delete<any>(this.getEndpointPath().concat("/").concat(id));
    };

    abstract getEndpointPath():string ;



}