import React from "react";
import EventHelper from "../../Shared/Utils/Helpers/EventHelper";
import {
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconFilter from "../../Shared/Layout/Svg/IconFilter";

const SWToggle = React.forwardRef(({ children, onClick, overrideFilterIcon, isFilter }: any, ref: any) => (
  <a
    className="toggle"
    href=""
    ref={ref}
    onClick={(e) => {
      EventHelper.stopPropagation(e);
      onClick(e);
    }}
    onKeyUp={EventHelper.stopPropagation}
  >
    {children}
    {overrideFilterIcon ?
      <IconFilter strokeColor={isFilter ? "white" : "#84847A"} />
      :
      <FontAwesomeIcon icon={faSearch} size="1x" color={isFilter ? "white" : "#3e3e3d"} />
    }
  </a>
));

export default SWToggle;
