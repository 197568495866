import React, { useCallback, useEffect, useImperativeHandle, useRef, useState, forwardRef } from "react";
import { IOption, ISWMultiSelectProps, ISelectElement } from "./SWInterface";
import "./SocleMultiSelect.scss";
import { Row, Col, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import _ from "lodash";
import IconCheckBox from "../../Shared/Layout/Svg/IconCheckBox";
import SocleMultiSelectInput from "./SocleMultiSelectInput";

/**
 * Composant MultiSelect permettant l'affichage d'un menu select à valeurs multiples
 *
 * ## Usage
 * ```tsx
 * const monSelectRef = useCallback(
    (node) => {
        if (node && node?.getCheckedItems) {
            const items = node?.getCheckedItems();
        }
    }, []);

    ...

    <SocleMultiSelect 
        ...
        ref={monSelectRef}
    />
 * ```
 */
const SocleMultiSelect = forwardRef((props: ISWMultiSelectProps, ref: any) => {

    const selectRef: any = useRef(null);

    const [options, setOptions] = useState<IOption[]>(props.data);
    const [searchValue, setSearchValue] = useState("");
    const [showMenu, setShowMenu] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [checkedItems, setcheckedItems] = useState<string[]>([]);

    const inputRef = useCallback(
        (node) => {
            if (node && showMenu) {
                node?.focus();
            }
        },
        [showMenu]
    );

    const element = props.rootBoundary
        ? document.querySelector(props.rootBoundary)
        : document.querySelector('#content-div');

    const popperConfig: any = {
        strategy: 'fixed',
        placement: 'top',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 10],
                },
            },
            {
                name: 'flip',
                options: {
                    rootBoundary: element,
                },
            },
        ],
    };

    // à chaque fois qu'une option est cochée/décochée, on notifie que les données ont changé
    useEffect(() => {
        props.notifyRefresh && props.notifyRefresh();
    }, [checkedItems, allChecked, options]);

    // Si on clique en dehors du composant alors on ferme le dropdown
    useEffect(() => {
        const handler = (e: any) => {
            if (selectRef.current && !selectRef.current?.contains(e.target)) {
                setShowMenu(false);
            }
        };

        window.addEventListener("click", handler);
        return () => {
            window.removeEventListener("click", handler);
        };
    });

    useEffect(() => {
        const items: string[] = [];
        options.forEach(parent => {
            if (!parent.children) {
                if (parent.checked) {
                    items.push(parent.label);
                }
            } else {
                parent.children?.forEach(child => {
                    if (child.checked) {
                        items.push(child.label);
                    }
                });
            }
        });
        const all = options.reduce((acc, option) => acc && (option.isDisabled || option.checked), true);
        setAllChecked(all);
        setcheckedItems(items);
    }, [options]);

    useImperativeHandle(ref, () => ({
        /**
         * Cocher les éléments de la liste ``checkItems`` et décocher le reste
         * @param checkItems les éléments à cocher
         */
        checkItems: (checkItems: string[]) => {
            const copy = _.cloneDeep(options);
            copy.forEach(parent => {
                if (!parent.isDisabled) {
                    if (parent.children) {
                        parent.children?.filter(child => !child.isDisabled)?.forEach(child => {
                            child.checked = checkItems?.includes(child?.value);
                        });
                        const everyChildrenChecked = parent.children?.filter(child => !child.isDisabled)?.every(c => c.checked);
                        parent.checked = everyChildrenChecked;
                    } else {
                        parent.checked = checkItems?.includes(parent?.value);
                    }
                }
            });
            setOptions(copy);
        },
        /**
         * Récupérer la liste des options cochées
         * @returns la liste des options cochées
         */
        getCheckedItems: () => {
            const items: any[] = [];
            options.forEach(parent => {
                if (!parent.children) {
                    if (parent.checked) {
                        items.push(parent);
                    }
                } else {
                    parent.children?.forEach(child => {
                        if (child.checked) {
                            items.push(child);
                        }
                    });
                }
            });
            return items;
        },
        /**
         * Décocher toutes les options
         */
        clearAll: () => {
            onSelectUnSelectAllHandler(true);
        },
    }));

    const onSelectUnSelectAllHandler = (actualValue: boolean) => {
        const newOptions = _.cloneDeep(options);
        setOptions(newOptions.map(parent => {
            if (!parent.isDisabled) {
                parent.checked = !actualValue;
                parent.children?.filter(child => !child.isDisabled)?.forEach(child => {
                    child.checked = !actualValue;
                });
            }
            return parent;
        }));
    };

    const cleanAllHandler = (e: any) => {
        onSelectUnSelectAllHandler(true);
        // On évite la fermeture du menu au clic sur la croix
        e.preventDefault();
        e.stopPropagation();
    };

    const onSearchHandler = (e: any) => {
        setSearchValue(e.target.value);
    };

    const onShowMenuHandler = () => {
        setShowMenu(!showMenu && !props.isDisabled);
    };

    const getOptions = () => {
        if (!searchValue) {
            return options;
        }
        const parent = options.filter(option => _.deburr(option.label.toLowerCase()).indexOf(_.deburr(searchValue.toLowerCase())) >= 0);
        const children = options.map(option => option.children)?.flat()
            ?.filter(option => {
                return option &&
                    option?.label?.toLowerCase() &&
                    !(parent.find(p => p.children?.includes(option))?.label?.toLowerCase()) &&
                    _.deburr(option?.label?.toLowerCase()).indexOf(_.deburr(searchValue.toLowerCase())) >= 0;
            });

        const array: any[] = [...parent];

        if (children) {
            array.push.apply(array, children);
        }
        return array;
    };

    const onSelectParentHandler = (item: IOption, actualValue?: boolean) => {
        const newOptions = _.cloneDeep(options);
        const index = newOptions.findIndex(o => o.value === item.value);
        if (index !== -1) {
            newOptions[index].checked = !actualValue;
            newOptions[index].children?.filter(child => !child.isDisabled)?.forEach(child => {
                child.checked = !actualValue;
            });
            setOptions(newOptions);
        } else {
            onSelectChildHandler(item, actualValue);
        }
    };

    const onSelectChildHandler = (child: IOption, actualValue?: boolean) => {
        const newOptions = _.cloneDeep(options);
        const index = newOptions.findIndex(o => o.children?.find(c => c.value === child.value));
        if (index !== -1) {
            newOptions[index].children?.filter(c => !c.isDisabled)?.forEach(c => {
                if (c.value === child.value) {
                    c.checked = !actualValue;
                }
            });
            const everyChildrenChecked = newOptions[index].children?.filter(c => !c.isDisabled)?.every(c => c.checked);
            newOptions[index].checked = everyChildrenChecked;
        }
        setOptions(newOptions);
    };

    const getPlaceholder = () => {
        if (checkedItems.length === 0) {
            return props.placeholder ? props.placeholder : "Select...";
        }
        let placeholder: string[] = [];
        for (let index = 0; index < checkedItems.length; index++) {
            if (index < props.selectedElementsPlaceholderLimit) {
                const element = checkedItems[index];
                placeholder.push(element);
            }
        }

        return <OverlayTrigger
            placement='auto'
            popperConfig={popperConfig}
            overlay={
                <Tooltip id="tooltip-auto" className="socle-multi-select-tooltip">
                    <strong>{checkedItems.join(", ")}</strong>
                </Tooltip>
            }
        >
            {({ ref, ...triggerHandler }) => (
                <span ref={ref} {...triggerHandler} className="socle-multi-select-label" id="label-span-id">{`${placeholder.join(", ")}${checkedItems.length > props.selectedElementsPlaceholderLimit ? "..." : ""}`} ({checkedItems.length})</span>
            )}
        </OverlayTrigger>;
    };

    const getCheckIcon = (item: ISelectElement) => {
        return <span>
            <IconCheckBox checked={item.checked || false} strokeColor="#9E9E9E" width='18px' height='18px' />
        </span>;
    };

    const getLabel = (item: ISelectElement) => {
        if (item.tooltipValue) {
            return <OverlayTrigger
                placement='auto'
                popperConfig={popperConfig}
                overlay={
                    <Tooltip id="tooltip-auto" className="socle-multi-select-tooltip">
                        <strong>{item.label}</strong>
                    </Tooltip>
                }
            >
                {({ ref, ...triggerHandler }) => (
                    <span ref={ref} {...triggerHandler} className="socle-multi-select-label" id="label-span-id">{item.label}</span>
                )}
            </OverlayTrigger>;
        }
        return <span className="socle-multi-select-label" id="label-span-id">{item.label}</span>
    };

    const displayLine = (item: IOption) => {
        return (
            <>
                <Row key={item.value} className="pt-2" onClick={() => { if (!item.isDisabled) { onSelectParentHandler(item, item.checked) } }}>
                    <Col sm={1}>
                        {!item.isDisabled ? getCheckIcon(item) : <span style={{ marginLeft: '18px' }}></span>}
                    </Col>
                    <Col style={{ opacity: item.isDisabled ? 0.5 : 1 }}>
                        {getLabel(item)}
                    </Col>
                </Row>
                {item.children?.map(child => (
                    <Row key={child.value} className="pb-1 pl-3" onClick={() => {
                        if (!child.isDisabled) {
                            onSelectChildHandler(child, child.checked);
                        }
                    }}>
                        <Col sm={1}>
                            {!child.isDisabled ? getCheckIcon(child) : <span style={{ marginLeft: '18px' }}></span>}
                        </Col>
                        <Col style={{ opacity: child.isDisabled ? 0.5 : 1 }}>
                            {getLabel(child)}
                        </Col>
                    </Row>
                ))}
            </>
        );
    };

    return (
        <div ref={ref} className={`socle-multiselect-dropdown-container ${props.isInvalid ? "is-invalid" : ""}`}>
            <Dropdown
                show={showMenu}
                onToggle={onShowMenuHandler}
                onKeyUp={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <div className="multiselect-dropdown">
                    <Dropdown.Toggle
                        as={SocleMultiSelectInput}
                        onClick={() => {
                            onShowMenuHandler();
                            if (props.onTouchHandler) {
                                props.onTouchHandler();
                            }
                        }}
                        getPlaceholder={getPlaceholder}
                        cleanAllHandler={cleanAllHandler}
                        props={props}
                        selectRef={selectRef}
                        showMenu={showMenu}
                        checkedItems={checkedItems}
                    />
                    <Dropdown.Menu
                        bsPrefix="multiselect-dropdown-menu"
                        rootCloseEvent="click"
                        flip
                        style={{ width: selectRef?.current?.clientWidth || 45 }}
                        popperConfig={popperConfig}
                    >
                        <div className="socle-multiselect-dropdown-menu" style={{ display: showMenu ? 'block' : 'none' }} onClick={(e: any) => e.stopPropagation()}>{/* On bloque la fermeture du dropdown si clic à l'intérieur */}
                            {props.isSearchable && (
                                <div className="row search-box">
                                    <input
                                        className="socle-multiselect-dropdown-input"
                                        onChange={onSearchHandler}
                                        value={searchValue}
                                        ref={inputRef}
                                        autoComplete="off"
                                        autoFocus
                                        placeholder="Filtrer"
                                    />
                                </div>
                            )}
                            <div className="socle-multiselect-dropdown-items">
                                {getOptions().length > 0 &&
                                    <>
                                        <Row className="pt-2" onClick={() => {
                                            if (getOptions().some(option => !option.isDisabled)) {
                                                onSelectUnSelectAllHandler(allChecked);
                                            }
                                        }}>
                                            <Col sm={1}>
                                                <span>
                                                    {getOptions().some(option => !option.isDisabled) ?
                                                        <IconCheckBox checked={allChecked} strokeColor="#9E9E9E" width='18px' height='18px' />
                                                        :
                                                        <span style={{ marginLeft: '18px' }}></span>}

                                                </span>
                                            </Col>
                                            <Col style={{ opacity: getOptions().some(option => !option.isDisabled) ? 1 : 0.5 }}>
                                                <span className="socle-multi-select-label">{`Tout ${allChecked ? "dé" : ""}sélectionner`}</span>
                                            </Col>
                                        </Row>
                                        {getOptions().map((option) => (
                                            displayLine(option)
                                        ))}
                                    </>
                                }
                                {getOptions().length === 0 &&
                                    <Row className="pt-2 pb-2">
                                        <Col sm={12} className="text-center aucun-resultat">
                                            Aucun résultat
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </div>
                    </Dropdown.Menu>
                </div>
            </Dropdown>
        </div>
    );
});

export default React.memo(SocleMultiSelect);