import React from "react";

const IconDoubleArrowLeft = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        fill="none"
        viewBox="0 0 35 35"
        {...props}
    >
        <rect x="34" y="34" width="33" height="33" rx="16.5" transform="rotate(-180 34 34)" fill="white" stroke="#408B7A" strokeWidth="2" />
        <mask id="mask0_1648_467214" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="16" y="11" width="6" height="12">
            <path fillRule="evenodd" clipRule="evenodd" d="M16 11L16 23L22 23L22 11L16 11Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1648_467214)">
            <path fillRule="evenodd" clipRule="evenodd" d="M22 22.4522C21.9994 22.3076 21.9446 22.1695 21.8478 22.0678L17.2293 16.9986L21.8478 11.9296C21.9459 11.8251 21.9999 11.683 21.9974 11.5355C21.9923 11.2343 21.7579 10.9946 21.474 11.0001C21.3354 11.0027 21.2037 11.0647 21.1087 11.1717L16.1446 16.6194C15.9518 16.8309 15.9518 17.1664 16.1446 17.3779L21.1087 22.8255C21.3019 23.0463 21.6272 23.0593 21.8355 22.8546C21.941 22.7507 22.0006 22.6048 22 22.4522Z" fill="#2F2F2F" />
        </g>
        <mask id="mask1_1648_467214" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="11" y="11" width="6" height="12">
            <path fillRule="evenodd" clipRule="evenodd" d="M11 11L11 23L17 23L17 11L11 11Z" fill="white" />
        </mask>
        <g mask="url(#mask1_1648_467214)">
            <path fillRule="evenodd" clipRule="evenodd" d="M17 22.4522C16.9994 22.3076 16.9446 22.1695 16.8478 22.0678L12.2293 16.9986L16.8478 11.9296C16.9459 11.8251 16.9999 11.683 16.9974 11.5355C16.9923 11.2343 16.7579 10.9946 16.474 11.0001C16.3354 11.0027 16.2037 11.0647 16.1087 11.1717L11.1446 16.6194C10.9518 16.8309 10.9518 17.1664 11.1446 17.3779L16.1087 22.8255C16.3019 23.0463 16.6272 23.0593 16.8355 22.8546C16.941 22.7507 17.0006 22.6048 17 22.4522Z" fill="#2F2F2F" />
        </g>
    </svg>
);

export default IconDoubleArrowLeft;
