import React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Placement } from 'react-bootstrap/esm/Overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltips from '../Tooltips/Tooltips';

interface IProps {
  placement?: Placement;
  icon?: any;
  info: any;
  rootBoundary?: string;
};

const InfoBulle = ({ placement, icon, info, rootBoundary }: IProps) => (
  <Tooltips
    placement={placement ? placement : "right"}
    customIcon={icon !== null || icon !== undefined}
    icon={icon ? icon : <FontAwesomeIcon className="icon-tooltip infobulle" icon={faInfoCircle} />}
    children={info}
    rootBoundary={rootBoundary}
  />
);

export default InfoBulle;
