import { Layout, Top, Content, LeftColumn, If, Row, Bottom } from './Shared/Layout';
import { Tooltips } from './Shared/UI/Tooltips';
import { SideBar } from './Shared/Layout/Sidebar';
import { Login } from './Components/Login';
import { InputCodePostal } from './Shared/UI/InputCodePostal';
import { InputEmail } from './Shared/UI/InputEmail';
import { InputTelephone } from './Shared/UI/InputTelephone';
import { Fieldset } from './Shared/Layout/Fieldset';
import { InputDatePicker } from './Shared/UI/InputDatePicker';
import { Message } from './Shared/UI/Message';
import { Logout } from "./Components/Logout";
import SocleDataTable from './Components/SocleDataTable/SocleDataTable';
import SocleDragDrop from './Components/SocleDragDrop/SocleDragDrop';
import { IPersistedSocleColumnDataTable, IComponentInteractSearchColumnToggleProps, ISocleColumDataTable, ISWDataTableProps } from './Components/SocleDataTable/SWInterface';
import { ISocleDragDropProps, ISocleDraggableItem, ISocleDraggableItemMap, ISocleDragDropFilterInput, ISocleDragDropColumn, ISocleDragDropMessage, ISocleDragDropEntity } from './Components/SocleDragDrop/SWInterface';
import SocleMultiSelect from './Components/SocleMultiSelect/SocleMultiSelect';
import { IOption, ISWMultiSelectProps, ISelectElement } from './Components/SocleMultiSelect/SWInterface';
import { Formulaire } from "./Shared/Forms";
import { SocleButtonPrimary, SocleButtonSecondary, IButtonProps } from "./Shared/UI/Button";
import { Health } from "./Components/Health/Health";
import "./index.scss";

/**
 * HttpService exports
 */
import TokenManager from './Http/services/TokenManager';
import PermissionManager from './Http/services/PermissionManager';

export { PermissionManager };
export { TokenManager };
export { LoginService } from './Http/services/LoginService';
export { SocleHttpService, AxiosResponse } from './Http/services/SocleHttpService';
export { ILoggerService } from './Http/interfaces/ILoggerService';
export { PostPutModel } from './Http/models/PostPutModel';
export { LoginModel } from './Http/models/LoginModel';
export { LoginResult } from './Http/models/LoginResult';
export { ErrorModel } from './Http/models/ErrorModel';
export { RequestBaseModel } from './Http/models/RequestBaseModel';
export { RequestResult } from './Http/models/RequestResult';
export { GlobalConfig } from './Http/common/GlobalConfig';
export { AbstractSocleService } from './Http/services/AbstractSocleService';
export { AbstractCrudService } from './Http/services/AbstractCrudService';
import { SocleConstants } from './Http/common/SocleConstants';
import { SocleHttpService } from './Http/services/SocleHttpService';
import { IconArrowHead, IconCheckBox, IconCross, IconDoubleArrowLeft, IconDoubleArrowRight, IconFilter, IconFilterBlank, IconRadio, IconToggleLeft, IconToggleRight, IconVerticalLine } from './Shared/Layout/Svg';
export { SocleConstants };

const socleService: SocleHttpService = SocleHttpService.getInstance(window.origin + process.env.REACT_APP_API_URL!);
const refreshToken = TokenManager.get(SocleConstants.REFRESH_TOKEN);
if (refreshToken) {
    socleService.get<any>('/refreshtoken', {
        params: { refreshToken: refreshToken },
    });
}


//  Layout
export { Layout, Top, Content, LeftColumn, If, Row, Bottom };

// Login

export { Login, Message, Logout }

//Input
export { InputCodePostal, InputEmail, InputTelephone, InputDatePicker, Formulaire };

//Fieldset
export { Fieldset };

// Tooltips
export { Tooltips };

// SideBar
export { SideBar };

// DataTable
export { SocleDataTable, ISWDataTableProps, ISocleColumDataTable, IPersistedSocleColumnDataTable, IComponentInteractSearchColumnToggleProps };

// DragDrop
export { SocleDragDrop, ISocleDragDropProps, ISocleDraggableItem, ISocleDraggableItemMap, ISocleDragDropFilterInput, ISocleDragDropColumn, ISocleDragDropMessage, ISocleDragDropEntity };

// ToastService and Node
export { ToasterService, Toasts, ToastModel, ToastType } from './Shared/Utils/Toasts';

// SocleRadioButton
export { SocleRadioButton } from './Shared/UI/RadioButton';

// SocleCheckButton
export { SocleCheckButton } from './Shared/UI/CheckButton';

// InfoBulle
export { InfoBulle } from './Shared/UI/InfoBulle';

// SocleMultiSelect
export { SocleMultiSelect, ISWMultiSelectProps, IOption, ISelectElement };

// SVG
export { IconArrowHead, IconCheckBox, IconCross, IconDoubleArrowLeft, IconDoubleArrowRight, IconFilter, IconFilterBlank, IconRadio, IconToggleLeft, IconToggleRight, IconVerticalLine };

// Button
export { SocleButtonPrimary, SocleButtonSecondary, IButtonProps };

// Monitoring
export { Health };
