export function convertPermissionV2ToV1(data: Map<string, Array<Map<number, string>>>) {
    const perms: Map<string, number[]> = new Map();
     Object.keys(data).forEach((perm) => {
       const codes: Array<Map<number, string>> = data[perm];
       const orgaId: Array<number> = [];
       codes.forEach(code => {
        orgaId.push(code['orgaId']);
         perms[perm] = orgaId;
       });
     });
     return perms;
} 

export const normalizeText = (text: any): string => {
  return text && text !== ''
    ? text
        ?.toString()
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '')
    : '';
};

export const parseKey = (d: any, key: any) => {
  // Si key contient un point, alors c'est un objet imbriqué (nested object)
  if (key.includes(".")) {
    const splitted = key.split(".");
    return d[splitted[0]][splitted[1]];
  }
  return d[key];
};