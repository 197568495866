import { GlobalConfig as GC } from '../common/GlobalConfig';
import { ErrorModel } from '../models/ErrorModel';
import { SafeResult } from '../models/SafeResult';
import { StatusErrorType } from '../models/StatusErrorType';
import { RequestResult } from '../models/RequestResult';
import { AbstractSocleService } from './AbstractSocleService';
import { convertPermissionV2ToV1 } from '../../Shared/Utils/Utils';
export class PermissionService extends AbstractSocleService {
  permissionEndpoint: string;
  constructor() {
    super();
    this.permissionEndpoint = '/users/current/permissions';
  }

  async getPermissions(): Promise<
    SafeResult<
      RequestResult<Map<string, Array<number>>>,
      RequestResult<ErrorModel>
    >
  > {
    GC.logger.log('Get permissions in PermissionService');
    const result = await this.getPermissionsV2();
    if (result.isSuccess()) {
      const perms: Map<string, number[]> = convertPermissionV2ToV1(result.value.data);
      const requestResult = new RequestResult<Map<string, Array<number>>>();
      requestResult.success = result.value.success;
      requestResult.data = perms;
      requestResult.status = result.value.status;
      return new SafeResult("OK", requestResult, undefined);
    } else {
      const requestResult = new RequestResult<ErrorModel>();
      const error: ErrorModel = new ErrorModel();
      error.code = result.error.data.code;
      error.message = result.error.data.message;
      error.status = result.error.data.status;
      error.timestamp = result.error.data.timestamp;
      requestResult.success = false;
      requestResult.data = error;
      requestResult.status = result.error.data.status;
      return new SafeResult("", undefined, requestResult);
    }
  }

  async getPermissionsV2<
    permissionResultType = Map<string, Array<Map<number, string>>>,
    errorType extends StatusErrorType = ErrorModel
  >(): Promise<
    SafeResult<
      RequestResult<permissionResultType>,
      RequestResult<errorType>
    >
  > {
    GC.logger.log('Get permissions in PermissionService');
    return await this.httpService.get<
      permissionResultType,
      errorType
    >(this.permissionEndpoint);
  }
}
