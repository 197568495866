import React from 'react';
import styled from '@emotion/styled';
import memoizeOne from 'memoize-one';
import { borderRadius, grid } from './constants';
import { Row, Col, FormControl } from 'react-bootstrap';
import { Droppable } from 'react-beautiful-dnd';
import SWItem from './SWItem';

const Container = styled.div`
  width: 100%;
  margin: ${grid}px;
  border-radius: ${borderRadius}px;
  border: 1px solid #7a869a;
  background-color: #f4f5f7;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h5`
  font-weight: 700;
  padding: ${grid}px;
`;

const Footer = styled.div`
  font-weight: 600;
  font-size: 16px;
  padding: 6px;
`;

const ItemList = styled.div`
  padding: ${grid}px;
  ${(props: any) => (props.index === 1 ? `height: 400px` : `height: 420px`)};
  overflow-y: auto;
  flex-grow: 1;
  transition: background-color 0.2s ease;
  ${(props: any) =>
        props.isDraggingOver
            ? `background-color: #DCDCDC`
            : `background-color: white`};
`;

const getSelectedMap = memoizeOne((selectedItemIds) =>
    selectedItemIds.reduce((previous: any, current: any) => {
        previous[current] = true;
        return previous;
    }, {})
);

export default class SWColumn extends React.Component<any, any> {

    render() {
        const column = this.props.column;
        const filterValues = this.props.filterValues;
        const columnOrder = this.props.columnOrder;
        const items = this.props.items;
        const index = this.props.index;
        const messages = this.props.messages;
        const selectedItemIds = this.props.selectedItemIds;
        const draggingItemId = this.props.draggingItemId;
        const showFooter = this.props.showFooter;
        const title: any = column?.title ? <Title className="SocleDragDropColumnTitle">{column?.title}</Title> : null;

        const inputs: any = column?.inputs ? (
            <Row>
                {column?.inputs.map((input: any, idx: number) => (
                    <Col
                        key={idx}
                        md={input?.md}
                        className={
                            idx === 1
                                ? 'px-0'
                                : idx === 0
                                    ? column?.inputs.length > 1
                                        ? 'pr-1'
                                        : ''
                                    : 'pl-1 pr-4'
                        }
                    >
                        <FormControl
                            name={input.id}
                            type={input.type}
                            value={filterValues[input.id]}
                            onChange={this.props.onChange}
                            placeholder={input?.label}
                            autoComplete="off"
                        />
                    </Col>
                ))}{' '}
            </Row>
        ) : null;

        return (
            <Container className="SocleDragDropColumn">
                {title}
                {inputs}
                <Droppable droppableId={column.id}>
                    {(provided, snapshot) => (
                        <ItemList
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                            index={index}
                            {...provided.droppableProps}
                        >
                            {items?.map((item: any, idx: number) => {
                                const isSelected = Boolean(
                                    getSelectedMap(selectedItemIds)[item?.id]
                                );
                                const isGhosting =
                                    isSelected &&
                                    Boolean(draggingItemId) &&
                                    draggingItemId !== item?.id;
                                return (
                                    <SWItem
                                        item={item}
                                        columnId={column.id}
                                        index={idx}
                                        key={idx}
                                        isSelected={isSelected}
                                        isGhosting={isGhosting}
                                        selectionCount={selectedItemIds.length}
                                        toggleSelection={this.props.toggleSelection}
                                        toggleSelectionInGroup={this.props.toggleSelectionInGroup}
                                        multiSelectTo={this.props.multiSelectTo}
                                        doubleClickMoveTo={this.props.doubleClickMoveTo}
                                    />
                                );
                            })}

                            {provided.placeholder}
                        </ItemList>
                    )}
                </Droppable>
                {showFooter ?
                    <>
                        {columnOrder.length === 1 ? (
                            <Footer>
                                {items.length === 0
                                    ? 'Aucun élément à afficher'
                                    : 'Affichage des éléments 1 à ' +
                                    items.length +
                                    ' sur ' +
                                    items.length}
                            </Footer>
                        ) : null}
                        {messages && index === 1 ? (
                            <Footer>
                                {items.length === 0
                                    ? 'Aucun élément à afficher'
                                    : items.length > 1
                                        ? items.length + ` ${messages?.plurial}`
                                        : items.length + ` ${messages?.singular}`}
                            </Footer>
                        ) : null}
                    </>
                    : null}
            </Container>
        );
    }
}