import React from "react";

interface Props {
    condition: boolean
}
export class If extends React.Component<Props> {
    render(){
        if(this.props.condition) return this.props.children ? this.props.children : null;
        else return null
    }
}