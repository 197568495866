import React from 'react';
import './InputDatePicker.scss';
import DatePicker from "react-date-picker";

interface Props {
  label: string;
  format: string;
  dayPlaceholder: string;
  monthPlaceholder: string;
  yearPlaceholder: string;
  value?: Date
  onChange: (value: any) => void;
}

interface State {
  value: Date;
}

export class InputDatePicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value:this.props.value || null
    }
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  public static defaultProps = {
    label: 'Date et Heure',
    format : "dd/MM/yyyy",
    dayPlaceholder: "JJ",
    monthPlaceholder: "MM",
    yearPlaceholder: "AAAA"
  };


  handleOnChange(e: any) {
    this.setState({
      value: new Date(e)
    }, () => this.props.onChange(this.state.value));
  }

  render() {
    const {
      label,
      format,
      dayPlaceholder,
      monthPlaceholder,
      yearPlaceholder
    } = this.props;

    return (
      <div className='simple-form-group'>

          {label && <label className='simple-text-label'>{label}</label>}
         <DatePicker dayPlaceholder={dayPlaceholder} monthPlaceholder={monthPlaceholder} yearPlaceholder={yearPlaceholder} onChange={this.handleOnChange} format={format} value={this.state.value}/>
        </div>
    );
  }
}