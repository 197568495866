import { ErrorModel } from "./ErrorModel";

export class SafeResult<SuccessType, ErrorType = ErrorModel> {
  constructor(message: string, value: SuccessType = undefined, error: ErrorType = undefined) {
    this.message = message;
    this.value = value;
    this.error = error
  }

  isSuccess(): boolean {
    return this.value != undefined;
  }
  error?: ErrorType;
  value?: SuccessType;
  message?: string;
}
