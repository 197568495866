import * as React from 'react';

const IconToggleLeft = (props: any) => (
  <svg
    width={35}
    height={46}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 .5h24.5v45H10A9.5 9.5 0 0 1 .5 36V10A9.5 9.5 0 0 1 10 .5Z"
      fill="#fff"
      fillOpacity={0.2}
      stroke="#fff"
    />
    <mask
      id="a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={16}
      y={13}
      width={9}
      height={18}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 13.818v16.364h9V13.818h-9Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 29.435a.73.73 0 0 0-.228-.524l-6.928-6.913 6.928-6.912a.73.73 0 0 0 .224-.538.759.759 0 0 0-.785-.73.785.785 0 0 0-.548.234l-7.446 7.429a.725.725 0 0 0 0 1.034l7.446 7.429a.792.792 0 0 0 1.09.04.732.732 0 0 0 .247-.55"
        fill="#fff"
      />
    </g>
    <mask
      id="b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={8}
      y={13}
      width={10}
      height={18}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 13.818v16.364h9V13.818h-9Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#b)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 29.435a.73.73 0 0 0-.228-.524l-6.928-6.913 6.928-6.912a.73.73 0 0 0 .224-.538.759.759 0 0 0-.785-.73.785.785 0 0 0-.548.234l-7.446 7.429a.725.725 0 0 0 0 1.034l7.446 7.429a.792.792 0 0 0 1.09.04.732.732 0 0 0 .247-.55"
        fill="#fff"
      />
    </g>
  </svg>
);

export default IconToggleLeft;
