import { Button, Spinner } from "react-bootstrap"
import { IButtonProps } from "../IButtonProps";
import "./ButtonPrimary.scss";
import React from "react";

export class SocleButtonPrimary extends React.Component<IButtonProps, {}> {
    static defaultProps: Partial<IButtonProps> = {
        isLoading: false
    };

    constructor(props: Readonly<IButtonProps>) {
        super(props);
    }

    render() {
        const { className, ...props2 } = this.props;
        return (
            <Button
                className={`btnPrimary ${className || ''}`}
                disabled={this.props.isLoading || this.props.disabled}
                {...props2}
            >
                {this.props.isLoading &&
                    <>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        &nbsp;
                    </>
                }
                {props2.children}
            </Button>
        );
    }
}