import React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Tooltips } from '../../Shared/UI/Tooltips';
import { Redirect } from 'react-router-dom';
import { LoginModel } from '../../Http/models/LoginModel';
import './Login.scss';
import { Message } from '../../Shared/UI/Message/Message';
import { Formulaire } from '../../Shared/Forms/Formulaire';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { PermissionService } from '../../Http/services/PermissionService';
import jwt_decode from 'jwt-decode';
import { SafeResult } from '../../Http/models/SafeResult';
import { RequestResult } from '../../Http/models/RequestResult';
import { GlobalConfig as GC } from '../../Http/common/GlobalConfig';
import PermissionManager from '../../Http/services/PermissionManager';
import { ErrorModel } from '../../Http/models/ErrorModel';
import { LoginService } from '../../Http/services/LoginService';
import TokenManager from '../../Http/services/TokenManager';
import { AxiosResponse } from 'axios';
import { SocleConstants } from '../../Http/common/SocleConstants';
import { convertPermissionV2ToV1 } from '../../Shared/Utils/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  redirectTo: string;
  subtitle: string;
  title: string;
  logo: any;
}

interface State {
  isAuth: boolean;
  errorMessage: string;
}

interface FormValues {
  utilisateur: string;
  password: string;
}

export class Login extends Formulaire<Props, State> {
  permissionService: PermissionService;
  loginService: LoginService;

  constructor(props: Props) {
    super(props);
    this.state = {
      isAuth: false,
      errorMessage: '',
    };
    this.permissionService = new PermissionService();
    this.loginService = new LoginService();
    this.createModel = this.createModel.bind(this);
    this.loginService.httpService.shouldLoginCallback = (
      response: AxiosResponse<ErrorModel>
    ) => {
      this.postError(response.data);
    };
  }
  getAbstractCrudService() {
    return null;
  }

  createModel(values: any) {
    return new LoginModel(values.utilisateur, values.password);
  }

  handleSubmit(values: any) {
    if (!this.validateForm()) return;
    const model = this.createModel(values);
    if (model) {
      this.loginService.login(model).then((response) => {
        if (response.isSuccess()) {
          this.postTraitement(response.value);
        }
      });
    }
  }

  postTraitement(response: any) {
    TokenManager.setOrReplace(SocleConstants.ACCES_TOKEN, response.accessToken);
    TokenManager.setOrReplace(
      SocleConstants.REFRESH_TOKEN,
      response.refreshToken
    );
    const decoded: any = jwt_decode(response.accessToken);
    TokenManager.setOrReplace(
      SocleConstants.NAME,
      decoded.prenom + ' ' + decoded.nom
    );
    const result = this.permissionService.getPermissionsV2();

    result.then(
      (
        resp: SafeResult<
          RequestResult<Map<string, Array<Map<number, string>>>>,
          RequestResult<ErrorModel>
        >
      ) => {
        if (resp.isSuccess()) {
          const v1 = convertPermissionV2ToV1(resp.value.data);
          PermissionManager.setOrReplacePermissions(v1);
          PermissionManager.setOrReplacePermissionsByCodeStructureNonSignifiant(
            resp.value.data
          );
          this.setState({
            isAuth: true,
          });
        } else {
          GC.logger.log('Get permissions ', 'PermissionService');
          return new SafeResult<
            RequestResult<Map<string, Array<Map<number, string>>>>,
            ErrorModel
          >('Failed to login', undefined, resp.error.data);
        }
      }
    );
  }

  postError(error: any) {
    if (error.code === 'BAD_CREDENTIALS') {
      this.setState({
        errorMessage: 'Identifiant/Mot de passe incorrects',
      });
    }
  }

  public static defaultProps = {
    subtitle: 'Accédez à votre espace',
    title: 'Connexion',
  };

  render() {
    const initialValues: FormValues = {
      utilisateur: '',
      password: '',
    };
    let { isAuth } = this.state;
    if (isAuth) {
      return (
        <Redirect
          to={{
            pathname: this.props.redirectTo,
            state: { from: window.location.pathname },
          }}
        />
      );
    }
    return (
      <div className="login">
        <div className="login-form">
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              this.handleSubmit(values);
              setSubmitting(false);
            }}
            validationSchema={yup.object().shape({
              utilisateur: yup
                .string()
                .required("L'identifiant est obligatoire"),
              password: yup
                .string()
                .required('Le mot de passe est obligatoire'),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="logo">
                  <img src={this.props.logo} alt="logo" />
                </div>
                <hr />
                <div className="title">{this.props.title}</div>
                <div className="subtitle">{this.props.subtitle}</div>
                <div className="controls">
                  {this.state.errorMessage.length > 0 && (
                    <div style={{ padding: '0.5rem' }}>
                      <Message type="error">{this.state.errorMessage}</Message>
                    </div>
                  )}
                  <Form.Group>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <Tooltips placement="top" icon={faUser}>
                            Mail ou identifiant ONF
                          </Tooltips>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        id="utilisateur"
                        name="utilisateur"
                        placeholder="Identifiant de connexion"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.utilisateur}
                        required
                        className={
                          touched.utilisateur && errors.utilisateur
                            ? 'error'
                            : null
                        }
                      />
                    </InputGroup>
                    {touched.utilisateur && errors.utilisateur ? (
                      <div className="error-form">{errors.utilisateur}</div>
                    ) : null}
                    <br />
                    <InputGroup>
                      <InputGroup.Prepend>
                          <InputGroup.Text>
                            <FontAwesomeIcon color='#408b7a' icon={faLock} />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                      <Form.Control
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Mot de passe"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        required
                        className={
                          touched.password && errors.password ? 'error' : null
                        }
                      />
                    </InputGroup>
                    {touched.password && errors.password ? (
                      <div className="error-form">{errors.password}</div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="actions">
                  <Button type="submit" disabled={isSubmitting}>
                    Connexion
                  </Button>
                </div>
                {this.props.children}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
