import React from "react";

const IconFilter = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
        stroke={props.strokeColor}
    >
        <path
            stroke={props.strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M1 5V2a1 1 0 011-1h16a1 1 0 011 1v3M1 5l6.65 5.7a1 1 0 01.35.76v6.26a1 1 0 001.243.97l2-.5a1 1 0 00.757-.97v-5.76a1 1 0 01.35-.76L19 5M1 5h18"
        ></path>
    </svg>
);

export default IconFilter;
