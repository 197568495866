import React from "react";

const IconAscSortNumber = () => (
    <svg width="17" height="17" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.96683 1.4671C4.96683 1.17702 5.08207 0.898821 5.28719 0.693703C5.4923 0.488585 5.7705 0.373352 6.06058 0.373352C6.35066 0.373352 6.62886 0.488585 6.83398 0.693703C7.0391 0.898821 7.15433 1.17702 7.15433 1.4671L7.15433 20.7018L9.66121 18.1927C9.7629 18.091 9.88363 18.0104 10.0165 17.9553C10.1494 17.9003 10.2918 17.872 10.4356 17.872C10.5794 17.872 10.7218 17.9003 10.8547 17.9553C10.9875 18.0104 11.1083 18.091 11.21 18.1927C11.3116 18.2944 11.3923 18.4151 11.4474 18.548C11.5024 18.6809 11.5307 18.8233 11.5307 18.9671C11.5307 19.1109 11.5024 19.2533 11.4474 19.3862C11.3923 19.5191 11.3116 19.6398 11.21 19.7415L6.81964 24.1296C6.61402 24.3293 6.33796 24.4399 6.05132 24.4375C5.76469 24.435 5.49057 24.3197 5.28839 24.1165L0.913393 19.7415C0.811701 19.6399 0.731006 19.5193 0.675916 19.3866C0.620825 19.2539 0.592418 19.1116 0.592316 18.9679C0.592214 18.8242 0.620421 18.6818 0.675323 18.549C0.730226 18.4162 0.810751 18.2955 0.9123 18.1938C1.01385 18.0921 1.13443 18.0114 1.26717 17.9563C1.3999 17.9013 1.54219 17.8728 1.6859 17.8727C1.82961 17.8726 1.97194 17.9008 2.10475 17.9557C2.23757 18.0107 2.35826 18.0912 2.45996 18.1927L4.96683 20.7018L4.96683 1.4671Z" fill="#84847A"/>
        <path d="M15.1328 23.0233C14.9277 22.8182 14.8125 22.54 14.8125 22.25C14.8125 21.9599 14.9277 21.6817 15.1328 21.4766C15.3379 21.2714 15.6161 21.1562 15.9062 21.1562L31.2187 21.1562C31.5088 21.1562 31.787 21.2714 31.9921 21.4766C32.1972 21.6817 32.3125 21.9599 32.3125 22.25C32.3125 22.54 32.1972 22.8182 31.9921 23.0233C31.787 23.2285 31.5088 23.3437 31.2187 23.3437L15.9062 23.3437C15.6161 23.3437 15.3379 23.2285 15.1328 23.0233Z" fill="#009878"/>
        <path d="M15.1328 16.4608C15.3379 16.666 15.6161 16.7812 15.9062 16.7812H26.8437C27.1338 16.7812 27.412 16.666 27.6171 16.4608C27.8222 16.2557 27.9375 15.9775 27.9375 15.6875C27.9375 15.3974 27.8222 15.1192 27.6171 14.9141C27.412 14.7089 27.1338 14.5937 26.8437 14.5937L15.9062 14.5937C15.6161 14.5937 15.3379 14.7089 15.1328 14.9141C14.9277 15.1192 14.8125 15.3974 14.8125 15.6875C14.8125 15.9775 14.9277 16.2557 15.1328 16.4608Z" fill="#009878"/>
        <path d="M15.1328 9.89835C15.3379 10.1035 15.6161 10.2187 15.9062 10.2187L22.4687 10.2187C22.7588 10.2187 23.037 10.1035 23.2421 9.89835C23.4472 9.69323 23.5625 9.41503 23.5625 9.12495C23.5625 8.83487 23.4472 8.55667 23.2421 8.35155C23.037 8.14644 22.7588 8.0312 22.4687 8.0312L15.9062 8.0312C15.6161 8.0312 15.3379 8.14644 15.1328 8.35155C14.9277 8.55667 14.8125 8.83487 14.8125 9.12495C14.8125 9.41503 14.9277 9.69323 15.1328 9.89835Z" fill="#009878"/>
        <path d="M15.1328 3.33585C15.3379 3.54097 15.6161 3.6562 15.9062 3.6562H18.0937C18.3838 3.6562 18.662 3.54097 18.8671 3.33585C19.0722 3.13073 19.1875 2.85253 19.1875 2.56245C19.1875 2.27237 19.0722 1.99417 18.8671 1.78905C18.662 1.58394 18.3838 1.4687 18.0937 1.4687L15.9062 1.4687C15.6161 1.4687 15.3379 1.58394 15.1328 1.78905C14.9277 1.99417 14.8125 2.27237 14.8125 2.56245C14.8125 2.85253 14.9277 3.13073 15.1328 3.33585Z" fill="#009878"/>
    </svg>
);

export default IconAscSortNumber;
