import { SocleHttpService } from "./SocleHttpService";
//Class parent Socle HttpService
export abstract class AbstractSocleService {
      public httpService: SocleHttpService;  
      public baseUrl =  window.origin + process.env.REACT_APP_API_URL!
      constructor(){
            this.httpService= SocleHttpService.getInstance(this.baseUrl);
      }
      
      
}