import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Dropdown } from "react-bootstrap";
import IconCheckBox from '../../Shared/Layout/Svg/IconCheckBox';
import { normalizeText } from "../../Shared/Utils/Utils";
import CActionsButtons from "./SWActionsButtons";
import { IFilterProps } from "./SWInterface";

const SWStringSelectFilter = forwardRef(
  ({ column, onFilter, onToggle, show }: IFilterProps, ref: any) => {
    const [input, setInput] = useState<string>('');
    const [columnData, setColumnData] = useState<string[]>(column?.dataList);
    const [dropdownCheckedList, setDropdownCheckedList] = useState<any[]>([]);

    const inputRef = useCallback(
      (node) => {
        if (node && show[column.dataField]) {
          node?.focus();
        }
      },
      [show[column.dataField]]
    );

    useEffect(() => {
      if (column.filter.props.getFilter) {
        column.filter.props.getFilter((filterVal: any) => {
          onFilter(filterVal);
        });
      }
    });

    useImperativeHandle(ref, () => ({
      onReset: () => {
        onReset();
      },
    }));

    useEffect(() => {
      if (column?.activeFilters) {
        setDropdownCheckedList(column?.activeFilters);
      }
    }, [column?.activeFilters]);

    useEffect(() => {
      if (column?.dataList) {
        const data: any = column?.dataList?.filter((d: any) =>
          normalizeText(d)
            .toLowerCase()
            .includes(normalizeText(input).toLowerCase())
        );

        setColumnData(data);
      }
    }, [input, column?.dataList]);

    const onFilterHandler = () => {
      onFilter(dropdownCheckedList);
    };

    const onSubmit = (e: any) => {
      e.preventDefault();
      onToggle(column.dataField, false);
      onFilterHandler();
    };

    const onReset = () => {
      setInput('');
      setDropdownCheckedList([]);
      onToggle(column.dataField, false);
      onFilter('');
    };

    const onCheckItem = (checked: boolean, data: any) => {
      const arrayHelpers: any[] = [...dropdownCheckedList];

      if (!checked) {
        arrayHelpers.push(data);
      } else {
        const idx = arrayHelpers.indexOf(data);
        if (idx > -1) arrayHelpers.splice(idx, 1);
      }

      setDropdownCheckedList(arrayHelpers);
    };

    const onCheckAll = (checked: boolean) => {
      if (!checked) {
        setDropdownCheckedList(columnData);
      } else {
        setDropdownCheckedList([]);
      }
    };

    return (
      <form onSubmit={onSubmit} noValidate className="p-2">
        <input
          ref={inputRef}
          className="table-input"
          type="text"
          value={input}
          placeholder={`Rechercher par ${column.text}`}
          onChange={(e: any) => setInput(e.target.value)}
          autoComplete="off"
          autoFocus
        />

        <ul className="table-dropdown-menu-content">
          {columnData && columnData.length > 0 && (
            <>
              <li className="table-dropdown-menu-item">
                <span 
                  id={`tout-selectionner ${column.dataField}`} 
                  onClick={() => onCheckAll(dropdownCheckedList.length === columnData.length)}>
                    <IconCheckBox checked={dropdownCheckedList.length === columnData.length} strokeColor="#9E9E9E" width='18px' height='18px' />
                  &nbsp;&nbsp;TOUT SELECTIONNER</span>
              </li>
              {columnData.map((d: any) => (
                <li key={d} className="table-dropdown-menu-item">
                  <span 
                    id={column.dataField + d}
                    onClick={() => onCheckItem(dropdownCheckedList.includes(d), d)}>
                      <IconCheckBox checked={dropdownCheckedList.includes(d)} strokeColor="#9E9E9E" width='18px' height='18px' />
                    &nbsp;&nbsp;{d}</span>
                </li>
              ))}
            </>
          )}
          {columnData.length === 0 && (
            <div className="text-center">Aucun résultat</div>
          )}
        </ul>

        <Dropdown.Divider />

        <CActionsButtons onSubmit={onSubmit} onReset={onReset} />
      </form>
    );
  }
);

export default SWStringSelectFilter;
