export default new (class TokenManager {
  setOrReplace(name:string,token: string): void {
    localStorage.setItem(name, token);
  }
  get(name:string): string {
    return localStorage.getItem(name);
  }

  removeItem(name:string): void {
     localStorage.removeItem(name);
  }

})();
