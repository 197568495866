import React, { forwardRef, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ISocleDragDropProps } from './SWInterface';
import { DragDropContext } from 'react-beautiful-dnd';
import { mutliDragAwareReorder, multiSelectTo as multiSelect, getItems } from './utils';
import { Button } from 'react-bootstrap';
import SWColumn from './SWColumn';
import Aux from '../../Shared/UI/TheAux/TheAux';
import "./SocleDragDrop.scss";
import _ from 'lodash';
import IconDoubleArrowRight from '../../Shared/Layout/Svg/IconDoubleArrowRight';
import IconDoubleArrowLeft from '../../Shared/Layout/Svg/IconDoubleArrowLeft';

const Container = styled.div`
  display: flex;
  user-select: none;
`;

/**
 * Composant DragDrop permettant l'affichage de données dans plusieurs colonnes séparées avec un glisé-déposé
 *
 * ## Usage
 * ```tsx
 * <SocleDragDrop 
 *      data={entities}
        updateEntities={(data: any) =>
            setEntities(data)
        }
        onDragEndHandler={onDragEndHandler}
    />
 * ```
 */
const SocleDragDrop = forwardRef(
    (
        {
            data,
            children,
            showFooter,
            onDragEndHandler,
            updateEntities,
        }: ISocleDragDropProps,
        ref?: any
    ) => {
        const [entities, setEntities] = useState(null as any);
        const [selectedItemIds, setSelectedItemIds] = useState([] as any);
        const [draggingItemId, setDraggingItemId] = useState(null);
        const [sourceIndex, setSourceIndex] = useState(null as any);
        const [sourceColumn, setSourceColumn] = useState(null as any);
        const [filterValues, setFilterValues] = useState<any>();

        useEffect(() => {
            window.addEventListener('click', onWindowClick);
            window.addEventListener('keydown', onWindowKeyDown);
            window.addEventListener('touchend', onWindowTouchEnd);

            setEntities(data);
            initFilterValues();

            return () => {
                window.removeEventListener('click', onWindowClick);
                window.removeEventListener('keydown', onWindowKeyDown);
                window.removeEventListener('touchend', onWindowTouchEnd);
            };
        }, []);

        useEffect(() => {
            if (data) {
                setEntities(data);
            }
        }, [data]);

        const initFilterValues = () => {
            const filter = Object.keys(data.columns).reduce((previous: any, current: any) => {
                previous[current] = '';
                return previous;
            }, {});
            setFilterValues(filter);
        };

        const onDragStart = (start: any) => {
            const id = start.draggableId;
            const selected = selectedItemIds.find((itemId: any) => itemId === id);
            if (!selected) {
                unselectAll();
            }
            setDraggingItemId(start.draggableId);
        };

        const onDragEnd = (result: any) => {
            const destination = result.destination;
            const source = result.source;
            if (!destination || result.reason === 'CANCEL') {
                setDraggingItemId(null);
                return;
            }
            const processed = mutliDragAwareReorder({
                entities: entities,
                selectedItemIds: selectedItemIds,
                source,
                destination,
            });

            onDragEndHandler(processed.entities.columns);

            updateEntities(processed.entities);

            setEntities(processed.entities);
            setDraggingItemId(null);
            initFilterValues();
            unselectAll();
        };

        const onWindowKeyDown = (event: any) => {
            if (event.defaultPrevented) {
                return;
            }
            if (event.key === 'Escape') {
                unselectAll();
            }
        };

        const onWindowClick = (event: any) => {
            if (event.defaultPrevented) {
                return;
            }
            unselectAll();
        };

        const onWindowTouchEnd = (event: any) => {
            if (event.defaultPrevented) {
                return;
            }
            unselectAll();
        };

        const toggleSelection = (
            itemId: any,
            srcIndex?: number,
            srcColumn?: string
        ) => {
            const wasSelected = selectedItemIds.includes(itemId);
            const newItemIds = (() => {
                if (!wasSelected) {
                    return [itemId];
                }
                if (selectedItemIds.length > 1) {
                    return [itemId];
                }
                return [];
            })();

            setSelectedItemIds(removeDisabledItemsFromSelection(newItemIds));
            setSourceIndex(srcIndex);
            setSourceColumn(srcColumn);
        };

        const removeDisabledItemsFromSelection = (items: any) => {
            return items.filter((i: any) => Object.values(data.items).some(item => item.id === i && !item.disabled));
        }

        const toggleSelectionInGroup = (
            itemId: any,
            srcIndex?: number,
            srcColumn?: string
        ) => {
            const index = selectedItemIds.indexOf(itemId);
            setSourceIndex(srcIndex);
            // L'utilisateur ne peut sélectionner que dans une seule colonne à la fois
            if (srcColumn && sourceColumn && srcColumn !== sourceColumn) {
                return;
            }
            setSourceColumn(srcColumn);
            if (index === -1) {
                setSelectedItemIds(removeDisabledItemsFromSelection([...selectedItemIds, itemId]));
                return;
            }
            const shallow = [...selectedItemIds];
            shallow.splice(index, 1);
            setSelectedItemIds(removeDisabledItemsFromSelection(shallow));
        };

        const multiSelectTo = (
            newItemId: any,
            srcIndex?: number,
            srcColumn?: string
        ) => {
            const updated = multiSelect(entities, selectedItemIds, newItemId);
            if (updated == null) {
                return;
            }
            setSourceIndex(srcIndex);
            // L'utilisateur ne peut sélectionner que dans une seule colonne à la fois
            if (srcColumn && sourceColumn && srcColumn !== sourceColumn) {
                return;
            }
            setSelectedItemIds(removeDisabledItemsFromSelection(updated));
            setSourceColumn(srcColumn);
        };

        const unselectAll = () => {
            setSelectedItemIds([]);
            setSourceColumn(null);
        };

        const onChangeHandler = (e: any) => {
            const name: string = e.currentTarget.name;
            const value: string = e.currentTarget.value;
            const newFilterValues: any = _.cloneDeep(filterValues);
            newFilterValues[name] = value;

            const entitiesBase = _.cloneDeep(data);
            const newEntities: any = _.cloneDeep(entities);

            const items: Array<any> = getItems(entitiesBase, name);

            const hiddenValues: Array<any> = items.filter((item: any) =>
                !item.content.toLowerCase().includes(value.toLowerCase())
            );

            const hiddenItemIds: Array<string> = hiddenValues.map((d: any) => d.id);

            newEntities.columns[name].hiddenItemIds = hiddenItemIds;

            setEntities(newEntities);

            setFilterValues(newFilterValues);
        };

        const moveTo = (index: number) => {
            const columns = [...entities.columnOrder];
            if (selectedItemIds.length > 0 && columns[index] !== sourceColumn) {
                moveToColumnHandler(columns, index, selectedItemIds);
            }
        };

        const doubleClickMoveTo = (item: any, srcColumn: any) => {
            const columns = [...entities.columnOrder];
            const index = columns.findIndex((c: any) => c !== srcColumn);
            setSourceColumn(srcColumn);
            setSelectedItemIds([item.id]);
            if (item && columns[index] !== srcColumn) {
                moveToColumnHandler(columns, index, [item.id]);
            }
        };

        const moveToColumnHandler = (columns: any[], index: number, itemIds: any) => {
            const source = {
                droppableId: index > 0 ? columns[0] : columns[1],
                index: sourceIndex,
            };

            const destination = {
                droppableId: columns[index],
                index: 0,
            };

            const processed = mutliDragAwareReorder({
                entities: entities,
                selectedItemIds: itemIds,
                source,
                destination,
            });

            onDragEndHandler(processed.entities.columns);

            updateEntities(processed.entities);

            setEntities(processed.entities);
            setDraggingItemId(null);
            unselectAll();
            initFilterValues();
        };

        let column = null;

        if (entities) {
            column = (
                <>
                    {entities.columnOrder.map((columnId: any, index: number) => {
                        const buttons = (
                            <div style={{ margin: 'auto' }}>
                                <Button variant="info-outline" className="SocleDragDropRightArrow mb-2" onClick={() => moveTo(1)}>
                                    <IconDoubleArrowRight />
                                </Button>
                                <Button variant="info-outline" className="SocleDragDropLeftArrow" onClick={() => moveTo(0)}>
                                    <IconDoubleArrowLeft />
                                </Button>
                            </div>
                        );
                        return (
                            <Aux key={columnId}>
                                <SWColumn
                                    column={entities.columns[columnId]}
                                    items={getItems(entities, columnId)}
                                    filterValues={filterValues}
                                    selectedItemIds={selectedItemIds}
                                    columnOrder={entities.columnOrder}
                                    key={columnId}
                                    index={index}
                                    draggingItemId={draggingItemId}
                                    toggleSelection={toggleSelection}
                                    toggleSelectionInGroup={toggleSelectionInGroup}
                                    multiSelectTo={multiSelectTo}
                                    onChange={onChangeHandler}
                                    doubleClickMoveTo={doubleClickMoveTo}
                                    messages={entities?.messages}
                                    showFooter={showFooter}
                                >
                                    {children}
                                </SWColumn>
                                {index === 0 && entities.columnOrder.length > 1 ? buttons : null}
                            </Aux>
                        );
                    })}
                </>
            );
        }

        if (!column) {
            return <div className="Spinner">
                <div className="loader">Loading...</div>
            </div>;
        }

        return (
            <div ref={ref} className="SocleDragDrop" style={{ overflow: 'auto' }}>
                <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                    <Container>{column}</Container>
                </DragDropContext>
            </div>
        );
    }
);
export default React.memo(SocleDragDrop);