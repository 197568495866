import { ILoggerService } from "../interfaces/ILoggerService";

export default new (class MockLogger implements ILoggerService {
  log(...args: any[]): void {
    console.log(this.getDateTimeString() + " - " + args[0] + " - " + args[1], args[2]);
  }
  private getDateTimeString(): string {
    var currentdate = new Date();
    var datetime =
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " @ " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();
    return datetime;
  }
})();
