import React from "react";

interface IProps {
    checked: boolean;
    strokeColor: string;
}

const IconRadio = (props: IProps) => (
    <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
        {props.checked ?
            <>
                <rect x="5" y="5" width="14" height="14" rx="7" fill="#408B7A" />
                <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke={props.strokeColor} />
            </>
            :
            <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke={props.strokeColor} />
        }
    </svg>
);

export default IconRadio;
