import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import { PostPutModel } from '../models/PostPutModel';
import { GlobalConfig } from '../common/GlobalConfig';
import { ErrorModel } from '../models/ErrorModel';
import { RequestResult } from '../models/RequestResult';
import { RequestBaseModel } from '../models/RequestBaseModel';
import { IHttpErrorHandler } from '../interfaces/IHttpErrorHandler';
import { SafeResult } from '../models/SafeResult';
import { StatusErrorType } from '../models/StatusErrorType';
import {
  ToasterService,
  ToastModel,
  ToastType,
} from '../../Shared/Utils/Toasts';
import HttpErrorMessage from '../../Shared/UI/Message/http-messages.json';
import TokenManager from './TokenManager';
import { SocleConstants } from '../common/SocleConstants';
import PermissionManager from './PermissionManager';
import { HttpStatusCode } from '../common/HttpStatusConstant';
import { convertPermissionV2ToV1 } from '../../Shared/Utils/Utils';

export { AxiosResponse };
export class SocleHttpService implements IHttpErrorHandler {
  private baseUrl: string;
  private static instance: SocleHttpService;
  axiosInstance: AxiosInstance;
  isRefreshPermission: boolean = false;
  shouldLoginCallback: (value: AxiosResponse) => void;
  notGoodRoleCallback: (value: AxiosResponse) => void;
  otherErrorCallback: (value: AxiosResponse) => void;
  private preLoggerInterceptor(toSend: AxiosRequestConfig) {
    GlobalConfig.logger.log(
      'Axios request is about to be sent. ',
      'HTTPService',
      toSend
    );
    return toSend;
  }
  private postLoggerInterceptor(response: AxiosResponse) {
    GlobalConfig.logger.log(
      'Response from ' + response.config.url,
      'HTTPService',
      response
    );
    return response;
  }

  public static getInstance(baseUrl: string): SocleHttpService {
    if (!this.instance) {
      this.instance = new SocleHttpService(baseUrl);
    }
    return this.instance;
  }
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: { 'Content-Type': 'application/json' },
    });
    this.axiosInstance.defaults.validateStatus = (status: number) => {
      return true;
    };

    //Setting up basic interceptors
    this.addPreRequestInterceptor((value: AxiosRequestConfig) => {
      return this.authPreInterceptor(value);
    });
    this.addPostRequestInterceptor((value: AxiosResponse<any>) => {
      return this.authPostInterceptor(value);
    });

    //Setting up loggers
    this.addPreRequestInterceptor(this.preLoggerInterceptor);
    this.addPostRequestInterceptor(this.postLoggerInterceptor);
  }

  //Token set up interceptor
  private authPreInterceptor(value: AxiosRequestConfig): AxiosRequestConfig {
    let token = TokenManager.get(SocleConstants.ACCES_TOKEN);
    if (token) {
      value.headers['Authorization'] = 'Bearer ' + token;
    } else {
      if (value.headers['Authorization']) delete value.headers['Authorization'];
    }
    return value;
  }

  async refreshToken(value: AxiosResponse) {
    const refreshToken = TokenManager.get(SocleConstants.REFRESH_TOKEN);
    await this.get<any>('/refreshtoken', {
      params: { refreshToken: refreshToken },
    }).then((response) => {
      if (response.isSuccess()) {
        TokenManager.setOrReplace(
          SocleConstants.ACCES_TOKEN,
          response.value.data.accessToken
        );
        TokenManager.setOrReplace(
          SocleConstants.REFRESH_TOKEN,
          response.value.data.refreshToken
        );
      } else {
        if (this.shouldLoginCallback != undefined) {
          this.shouldLoginCallback(value);
        }
      }
    });
  }

  //401 & 403 error interceptor callback
  private async authPostInterceptor(
    value: AxiosResponse
  ): Promise<AxiosResponse> {
    if (value.status == HttpStatusCode.HTTP_UNAUTHORIZED) {
      //Refresh Token
      if (value.data.code === SocleConstants.TOKEN_JWT_EXPIRE) {
        // Refresh Token expiré
        if (value.config.url.includes('refreshtoken')) {
          localStorage.clear();
          window.location.replace(
            window.origin + process.env.PUBLIC_URL + SocleConstants.LOGIN_PAGE
          );
        }
        await this.refreshToken(value);
        value = await this.retryRequest(value.config);
      } else if (this.shouldLoginCallback != undefined) {
        this.shouldLoginCallback(value);
      } else {
        localStorage.clear();
        window.location.replace(
          window.origin + process.env.PUBLIC_URL + SocleConstants.LOGIN_PAGE
        );
      }
    } else if (value.status == HttpStatusCode.HTTP_FORBIDDEN) {
      this.postError(HttpErrorMessage.FORBIDDEN);
      if (!this.isRefreshPermission) {
        this.postPermissionRequest();
      }
    } else if (
      value.status == HttpStatusCode.HTTP_INTERNAL_SERVER_ERROR &&
      value.data.code === SocleConstants.OPTIMISTIC_LOCK
    ) {
      this.postError(HttpErrorMessage.OPTIMISTIC_LOCK);
    } else if (value.status == HttpStatusCode.HTTP_INTERNAL_SERVER_ERROR) {
      this.postError(HttpErrorMessage.ERREUR_INTERNE);
    } else if (value.status == HttpStatusCode.HTTP_NOT_FOUND) {
      this.postError(value.data.message);
    } else if (value.status == HttpStatusCode.HTTP_BAD_REQUEST) {
      this.postError(value.data.message);
    } else if (
      value.status >= HttpStatusCode.HTTP_BAD_REQUEST &&
      this.otherErrorCallback != undefined
    ) {
      this.otherErrorCallback(value);
    }
    return value;
  }

  postPermissionRequest() {
    this.isRefreshPermission = true;
    this.get('/users/current/permissions').then(
      (
        resp: SafeResult<
          RequestResult<Map<string, Array<Map<number, string>>>>,
          RequestResult<ErrorModel>
        >
      ) => {
        if (resp.isSuccess()) {
          const v1 = convertPermissionV2ToV1(resp.value.data);
          PermissionManager.setOrReplacePermissions(v1);
          PermissionManager.setOrReplacePermissionsByCodeStructureNonSignifiant(
            resp.value.data
          );
          this.isRefreshPermission = false;
        } else {
          GlobalConfig.logger.log(
            'Get permissions aprés une erreur 403 ',
            'SocleHttpService'
          );
        }
      }
    );
  }

  postError(errorMessage: any) {
    ToasterService.push(
      new ToastModel(ToastType.Error, errorMessage, -1, true)
    );
  }
  addPreRequestInterceptor(interceptor: Function) {
    this.axiosInstance.interceptors.request.use((value: AxiosRequestConfig) => {
      return interceptor(value);
    });
  }

  addPostRequestInterceptor(interceptor: Function) {
    this.axiosInstance.interceptors.response.use((value: AxiosResponse) => {
      return interceptor(value);
    });
  }

  async retryRequest(config: AxiosRequestConfig): Promise<AxiosResponse> {
    return await this.axiosInstance.request(config);
  }

  async post<t, errorType extends StatusErrorType = ErrorModel>(
    endpoint: string,
    postModel: PostPutModel
  ): Promise<SafeResult<RequestResult<t>, RequestResult<errorType>>> {
    let response = await this.axiosInstance.post<t | errorType>(
      this.baseUrl + endpoint,
      postModel.data,
      { params: postModel.params! }
    );

    return this.responseToRequestResult<t, errorType>(response);
  }

  async put<t, errorType extends StatusErrorType = ErrorModel>(
    endpoint: string,
    postModel: PostPutModel
  ): Promise<SafeResult<RequestResult<t>, RequestResult<errorType>>> {
    const fullUrl = this.baseUrl + endpoint;

    let response = await this.axiosInstance.put<t | errorType>(
      this.baseUrl + endpoint,
      postModel.data,
      { params: postModel.params! }
    );

    return this.responseToRequestResult<t, errorType>(response);
  }

  async patch<t, errorType extends StatusErrorType = ErrorModel>(
    endpoint: string,
    postModel: PostPutModel
  ): Promise<SafeResult<RequestResult<t>, RequestResult<errorType>>> {
    const fullUrl = this.baseUrl + endpoint;

    let response = await this.axiosInstance.patch<t | errorType>(
      this.baseUrl + endpoint,
      postModel.data,
      { params: postModel.params! }
    );

    return this.responseToRequestResult<t, errorType>(response);
  }

  async get<t, errorType extends StatusErrorType = ErrorModel>(
    endpoint: string,
    conf: RequestBaseModel = new RequestBaseModel()
  ): Promise<SafeResult<RequestResult<t>, RequestResult<errorType>>> {
    let response = await this.axiosInstance.get<t | errorType>(
      this.baseUrl + endpoint,
      { params: conf.params! }
    );
    return this.responseToRequestResult<t, errorType>(response);
  }

  async delete<t, errorType extends StatusErrorType = ErrorModel>(
    endpoint: string,
    conf: RequestBaseModel = new RequestBaseModel()
  ): Promise<SafeResult<RequestResult<t>, RequestResult<errorType>>> {
    let response = await this.axiosInstance.delete<t | errorType>(
      this.baseUrl + endpoint,
      { params: conf.params }
    );
    return this.responseToRequestResult<t, errorType>(response);
  }

  private responseToRequestResult<t, errorType extends StatusErrorType>(
    response: AxiosResponse
  ): SafeResult<RequestResult<t>, RequestResult<errorType>> {
    if (response.status >= HttpStatusCode.HTTP_BAD_REQUEST) {
      let responseToReturn = new RequestResult<errorType>();
      responseToReturn.status = response.status;
      responseToReturn.data = response.data;
      responseToReturn.data.status = response.status;
      responseToReturn.success =
        response.status < HttpStatusCode.HTTP_BAD_REQUEST;
      return new SafeResult<RequestResult<t>, RequestResult<errorType>>(
        '',
        undefined,
        responseToReturn
      );
    } else {
      let responseToReturn = new RequestResult<t>();
      responseToReturn.status = response.status;
      responseToReturn.data = response.data;
      responseToReturn.success =
        response.status < HttpStatusCode.HTTP_BAD_REQUEST;
      return new SafeResult<RequestResult<t>, RequestResult<errorType>>(
        '',
        responseToReturn
      );
    }
  }
}
