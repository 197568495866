import React from 'react';
import {  ToasterService, ToastModel, ToastType } from "../Utils/Toasts";
import { AbstractCrudService } from '../../Http/services/AbstractCrudService';
import { PostPutModel } from '../../Http/models/PostPutModel';

export abstract class Formulaire<P, S> extends React.Component<P, S> {
  constructor(props: any) {
    super(props);  
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  abstract getAbstractCrudService(): AbstractCrudService;
  
  abstract createModel(values:any): PostPutModel;
  validateForm(): boolean {
    return true;
  }

  postTraitement(response: any) {
    ToasterService.push(
      new ToastModel(ToastType.Success, 'Opération effectuée avec succès', 5000, false)
    );
  }
  postError(error: any) {
    ToasterService.push(
      new ToastModel(ToastType.Error, error.data.message, -1, true)
    );
  }
 
  handleSubmit(values: any) {
    if (!this.validateForm()) return;
    
    const model = this.createModel(values);
    if (model.data.id) {
      this.getAbstractCrudService().update(model,model.data.id)
        .then((response) => {
          if (response.isSuccess()) {
            this.postTraitement(response.value.data);
          } else {
            this.postError(response.error);
          }
        });
      } else{
      this.getAbstractCrudService().create(model).then((response) => {
        if (response.isSuccess()) {
          this.postTraitement(response.value.data);
        } else {
          this.postError(response.error);
        }
      });
    }
  }
}
