import React, { useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Placement } from "react-bootstrap/esm/Overlay";
import "./Tooltips.scss";

interface IProps {
  placement: Placement;
  customIcon?: boolean;
  icon: any;
  children: any;
  rootBoundary?: string;
};

const Tooltips = ({ placement, customIcon, icon, children, rootBoundary }: IProps) => {
  const tooltipsRef = useRef(null as any);

  const element = rootBoundary
    ? document.querySelector(rootBoundary)
    : document.querySelector('#content-div');

  const popperonfig: any = {
    strategy: 'fixed',
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'flip',
        options: {
          rootBoundary: element,
        },
      },
    ],
  };

  return (
    <span ref={tooltipsRef}>
      <OverlayTrigger
        placement={placement}
        container={tooltipsRef}
        popperConfig={popperonfig}
        overlay={
          <Tooltip id={`tooltip-${placement}`}>
            {children}
          </Tooltip>
        }
      >
        {customIcon ? icon : <FontAwesomeIcon className="icon-tooltip" icon={icon} />}
      </OverlayTrigger>
    </span>

  );
};

export default Tooltips;