import React from "react";
import { Spinner } from "react-bootstrap";
import { IconCross, IconVerticalLine, IconArrowHead } from "../../Shared/Layout/Svg";
import EventHelper from "../../Shared/Utils/Helpers/EventHelper";

const SocleMultiSelectInput = React.forwardRef(({ checkedItems, showMenu, selectRef, props, cleanAllHandler, getPlaceholder, onClick }: any, ref: any) => (
    <div
        className="toggle"
        ref={ref}
        onClick={(e) => {
            EventHelper.stopPropagation(e);
            if (!props.isDisabled){
                onClick(e);
            }
        }}
        onKeyUp={EventHelper.stopPropagation}
    >
        <div ref={selectRef} className={`socle-multiselect-dropdown-input ${showMenu ? "active" : ""} ${(props.isLoading || props.isDisabled) ? "disabled" : ""}`} onClick={onClick}>
            <div className="socle-multiselect-dropdown-selected-value">{getPlaceholder()}</div>
            <div className="socle-multiselect-dropdown-tools">
                <div className={`socle-multiselect-dropdown-tool`}>
                    {props.isLoading && <Spinner animation="border" size="sm" className="align-middle" />}
                    {(props.canClearAll && !props.isLoading && (checkedItems.length > 0)) && <span onClick={cleanAllHandler} className="pr-1"><IconCross height="18" width="18" fillColor={props.isInvalid ? "#dc3545" : "rgb(215, 215, 205)"} /></span>}
                    <span className={`pr-2 ${props.isLoading ? "pl-2" : ""}`}><IconVerticalLine width="2" height="23" strokeColor={props.isInvalid ? "#dc3545" : "#D7D7CD"} /></span>
                    <span className="pr-1"><IconArrowHead width="14" height="8" fillColor={props.isInvalid ? "#dc3545" : "#D7D7CD"} /></span>
                </div>
            </div>
        </div>
    </div>
));

export default SocleMultiSelectInput;