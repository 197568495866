import React from 'react';
import styled from '@emotion/styled';
import { borderRadius, gridItem, keyCodes, primaryButton, size } from './constants';
import { Row, Col } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';

const getBackgroundColor = ({ isSelected, isGhosting, isDisabled }: any) => {
    if (!isDisabled) {
        if (isGhosting) {
            return '#C4C4C4';
        }
        if (isSelected) {
            return '#408b7a';
        }
        return 'white';
    } else {
        return 'white';
    }
};

const getBorderColor = ({ isDisabled }: any) => {
    if (!isDisabled) {
        return '#408b7a';
    } else {
        return 'darkgrey';
    }
};

const getColor = ({ isSelected, isGhosting, isDisabled }: any) => {
    if (!isDisabled) {
        if (isGhosting) {
            return 'darkgrey';
        }
        if (isSelected) {
            return 'white';
        }
        return '#091e42';
    } else {
        return 'darkgrey';
    }
};

const Container = styled.div`
    background-color: ${(props: any) => getBackgroundColor(props)};
    color: ${(props: any) => getColor(props)};
    padding: ${gridItem}px;
    margin-bottom: 6px;
    border-radius: ${borderRadius}px;
    border: 1px solid ${(props: any) => getBorderColor(props)};
    font-size: 16px;
    display: ${(props: any) => props.display};
  `;

const SelectionCount = styled.div`
    color: #ffffff;
    background: #6b778c;
    border-radius: 50%;
    height: ${size}px;
    width: ${size}px;
    line-height: ${size}px;
    text-align: center;
    font-size: 0.8rem;
  `;

export default class SWItem extends React.Component<any, any> {

    onKeyDown = (event: any, provided: any, snapshot: any) => {
        if (event.defaultPrevented) {
            return;
        }
        if (snapshot.isDragging) {
            return;
        }
        if (event.keyCode !== keyCodes.enter) {
            return;
        }

        event.preventDefault();
        this.performAction(event);
    };

    onClick = (event: any) => {
        if (event.defaultPrevented) {
            return;
        }

        if (event.button !== primaryButton) {
            return;
        }
        event.preventDefault();
        this.performAction(event);
    };

    onDoubleClick = (event: any) => {
        if (event.defaultPrevented) {
            return;
        }

        if (event.button !== primaryButton) {
            return;
        }
        event.preventDefault();

        const {
            item,
            columnId,
            doubleClickMoveTo,
        } = this.props;

        if (!item?.disabled) {
            doubleClickMoveTo(item, columnId);
        }
    };

    onTouchEnd = (event: any) => {
        if (event.defaultPrevented) {
            return;
        }
        event.preventDefault();
        this.props.toggleSelectionInGroup(this.props.item.id);
    };

    wasToggleInSelectionGroupKeyUsed = (event: any) => {
        const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
        return isUsingWindows ? event.ctrlKey : event.metaKey;
    };

    wasMultiSelectKeyUsed = (event: any) => event.shiftKey;

    performAction = (event: any) => {
        const {
            item,
            index,
            columnId,
            toggleSelection,
            toggleSelectionInGroup,
            multiSelectTo,
        } = this.props;
        if (this.wasToggleInSelectionGroupKeyUsed(event)) {
            toggleSelectionInGroup(item.id, index, columnId);
            return;
        }
        if (this.wasMultiSelectKeyUsed(event)) {
            multiSelectTo(item.id, index, columnId);
            return;
        }
        toggleSelection(item.id, index, columnId);
    };

    render() {
        const item = this.props.item;
        const index = this.props.index;
        const isSelected = this.props.isSelected;
        const selectionCount = this.props.selectionCount;
        const isGhosting = this.props.isGhosting;
        const words: Array<any> = item?.content.split(' * ');

        const content = (
            <Row className="SocleDragDropItemContentRow">
                {words.map((w: any, idx: number) => (
                    <Col
                        key={idx}
                        md={
                            words.length === 1
                                ? 12
                                : words.length === 2
                                    ? idx === 0 ? 2 : 9
                                    : idx === 0 || idx === 1
                                        ? 2
                                        : 8
                        }
                        className="p-0"
                    >
                        <Row>
                            <Col sm="12" className="SocleDragDropItemContent">
                                <div className={`item pt-1 pb-1 pl-3 pr-3 ${item?.disabled && "is-disabled"}`}>
                                    {item?.icon &&
                                        <span className="item-icon pr-1">
                                            {item?.icon}
                                        </span>
                                    }
                                    <p className={item?.icon ? "pl-3 m-0" : "pl-1 m-0"}>
                                        {w}
                                    </p>
                                </div>

                            </Col>
                        </Row>
                    </Col>
                ))}{' '}
            </Row>
        );

        return (
            <Draggable key={item?.id} draggableId={item?.id} index={index} isDragDisabled={item?.disabled}>
                {(provided, snapshot) => {
                    const shouldShowSelection = snapshot.isDragging && selectionCount > 1;

                    return (
                        <Container
                            className="SocleDragDropItem"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={this.onClick}
                            onDoubleClick={this.onDoubleClick}
                            onTouchEnd={this.onTouchEnd}
                            onKeyDown={(event) => this.onKeyDown(event, provided, snapshot)}
                            isDragging={snapshot.isDragging}
                            isSelected={isSelected}
                            isGhosting={isGhosting}
                            isDisabled={item?.disabled}
                            display={item?.display}
                        >
                            {content}
                            {shouldShowSelection ? (
                                <SelectionCount>{selectionCount}</SelectionCount>
                            ) : null}
                        </Container>
                    );
                }}
            </Draggable>
        );
    };
};