import SocleDataTable from '../SocleDataTable/SocleDataTable';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { ISocleColumDataTable } from '../SocleDataTable/SWInterface';
import { ComponentRow } from './../../Components/Health/HealthCheckInterface';

const columns: ISocleColumDataTable[] = [
    { dataField: 'name', text: 'Name' },
    { dataField: 'value', text: 'Value' },
];

interface ModalProps {
    showModal: boolean;
    handleCloseModal: () => void;
    modalContent: ComponentRow;
    loading: boolean;
}

const HealthDetailsModale: React.FC<ModalProps> = ({ showModal, handleCloseModal, modalContent, loading }) => {

    const hidePagination = (): JSX.Element => {
        return <div />;
    };

    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{modalContent?.name}</Modal.Title>
                <Modal.Title>Properties</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SocleDataTable
                    keyField='name'
                    data={modalContent?.details}
                    columns={columns}
                    hideSearchBar
                    hideColumnSelector
                    paginationOptions={{
                        hideSizePerPage: true,
                        paginationTotalRenderer: hidePagination,
                        pageListRenderer: hidePagination,
                    }}
                    loadingOptions={{
                        active: loading
                    }}
                />
            </Modal.Body>
        </Modal>
    );
};

export default React.memo(HealthDetailsModale);