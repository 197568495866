import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import ToasterService, { ToastModel } from './ToasterService';
import './Toasts.scss';

interface IToastsProps {
  stackToasts?: boolean;
}

interface IToastsState {
  toasts: ToastModel[];
  dateTime: string;
}
export class Toasts extends React.Component<IToastsProps, IToastsState> {
  static defaultProps: Partial<IToastsProps> = {
    stackToasts: false
  };
  
  constructor(props: Readonly<IToastsProps>) {
    super(props);

    this.state = {
      toasts: [],
      dateTime: '',
    };
    this.render = this.render.bind(this);
    this.addToastCallBack = this.addToastCallBack.bind(this);
    ToasterService.setOnPushCallback(this.addToastCallBack);
  }
  
public now: string;

  addToastCallBack(model: ToastModel) {
    const date = new Date();

    this.now = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate() )+
    '/' +
    ( ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() +1 ) : (date.getMonth() +1 ) )+
    '/' +
    date.getFullYear() +
    ' ' +
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours() )+
    ':' +
    ( date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() );
    let toastsCopy = this.state.toasts;
    toastsCopy.push(model);
    if (!this.props.stackToasts && toastsCopy.length > 1) {
      toastsCopy = toastsCopy.slice(toastsCopy.length - 1);
    }
    this.setState({
      toasts: toastsCopy,
    });
    model.content = model.content.toString().replace("\n", '<br/>');
    if (model.timeout !== -1) {
      setTimeout(() => {
        let toastsCopy = this.state.toasts;
        delete toastsCopy[toastsCopy.indexOf(model)];
        this.setState({ toasts: toastsCopy });
      }, model.timeout);
    }
  }

  render() {
    return (
      <div className={"toastQueue " + (this.props.stackToasts ? 'stackedToasts' : '')}>
        {this.state.toasts.map((t, index) => {
          let borderStyle;
          switch (t.type) {
            case "error":
              borderStyle = {
                border: '1px solid #a53131'
              }
              break;
              case "success":
              borderStyle = {
                border: '1px solid #59a491'
              }
              break;
              case "warning":
              borderStyle = {
                border: '1px solid #d9611c'
              }
              break;
              case "info":
              borderStyle = {
                border: '1px solid #029acc'
              }
              break;

          }
          return (
            <div key={index} className={'toastElem'} style={borderStyle}>
              <div className={'toastElem ' + t.type}>
                <span>
                  {/*Type du toast */}
                  {t.type === 'success' && (
                    <span>
                      <FontAwesomeIcon icon={faCheckCircle} /> Succès{' '}
                    </span>
                  )}
                  {t.type === 'error' && (
                    <span>
                      <FontAwesomeIcon icon={faTimesCircle} /> Erreur{' '}
                    </span>
                  )}
                  {t.type === 'warning' && (
                    <span>
                      <FontAwesomeIcon icon={faExclamationCircle} /> Warning{' '}
                    </span>
                  )}
                  {t.type === 'info' && (
                    <span>
                      <FontAwesomeIcon icon={faInfoCircle} /> Information{' '}
                    </span>
                  )}
                  {/* Icone X pour fermer le toast */}
                  <span
                    style={{ float: 'right' }}
                    onClick={(e) => {
                      let toastsSave = this.state.toasts;
                      let index = toastsSave.indexOf(t);
                      if (index != undefined) {
                        delete toastsSave[toastsSave.indexOf(t)];
                        this.setState({ toasts: toastsSave });
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </span>
              </div>
              <p className="toastBody" dangerouslySetInnerHTML={{ __html: t.content.toString() }}></p>
              {t.dateTime &&  <p className="toastFooter">{this.now}</p>}
            </div>
          );
        })}
      </div>
    );
  }
}
