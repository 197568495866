import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import BootstrapTable, { ColumnDescription, PaginationOptions, SelectRowProps } from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import LoadingOverlay from 'react-loading-overlay-ts';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {
  faBoxOpen,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleRight,
  faAngleLeft,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './SocleDataTable.scss';
import SWDefaultFilter from './SWDefaultFilter';
import SWHeaderFormatter from './SWHeaderFormatter';
import { IPersistedSocleColumnDataTable, ISWDataTableProps } from './SWInterface';

import SWStringSelectFilter from './SWStringSelectFilter';
import { normalizeText, parseKey } from '../../Shared/Utils/Utils';
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import SWColumnToggle from './SWColumnToggle';
import IconCheckBox from '../../Shared/Layout/Svg/IconCheckBox';
import IconRadio from '../../Shared/Layout/Svg/IconRadio';
import SWSizePerPage from './SWSizePerPage';
import { Tooltips } from '../../Shared/UI/Tooltips';
import SWLabelValueSelectFilter from './SWLabelValueSelectFilter';
import SvgDefaultSort from '../../Shared/Layout/Svg/IconGeneralSort';
import SvgAscSortNumber from "../../Shared/Layout/Svg/IconAscSortNumber";
import SvgAscSortString from '../../Shared/Layout/Svg/IconAscSortString';
import SvgDescSortNumber from '../../Shared/Layout/Svg/IconDescSortNumber';
import SvgDescSortString from '../../Shared/Layout/Svg/IconDescSortString';

const customTotal = (from: any, to: any, size: any) => (
  <span className="react-bootstrap-table-pagination-total">
    <span className="font-weight-600">{from}</span> -{' '}
    <span className="font-weight-600">{to}</span> /{' '}
    <span className="font-weight-600">{size}</span>
  </span>
);

const pageListRenderer = ({ pages, onPageChange }: any) => {
  // just exclude <, <<, >>, c
  const pageWithoutIndication = pages.filter(
    (p: any) => typeof p.page === 'string'
  );

  return (
    <div className="pagination-btns">
      {pageWithoutIndication.map((p: any, index: number) => {
        let icon: any = null;
        switch (p.page) {
          case '<':
            icon = faAngleLeft;
            break;

          case '<<':
            icon = faAngleDoubleLeft;
            break;

          case '>>':
            icon = faAngleDoubleRight;
            break;

          case '>':
            icon = faAngleRight;
            break;

          default:
            break;
        }
        return (
          <div
            className={`pagination-btn ${p?.disabled ? 'disable' : ''}`}
            key={index}
            onClick={() => onPageChange(p.page)}
          >
            <FontAwesomeIcon icon={icon} />
          </div>
        );
      })}
    </div>
  );
};

const CustomSearch = (props: any) => {

  useEffect(() => {
    if(props.globalFilterValue !== null && props.searchByManualEntry) {
      if(props.globalFilterValue.length == 0) {
        searchFilter();
      } else {
        const timerId = setTimeout(() => {
          if (props.globalFilterValue.length >= props.searchMinLength) {
            searchFilter();
          }
        }, props.searchLatencyMs);
    
        return () => {
          clearTimeout(timerId);
        };
      }
    }
  }, [props.globalFilterValue]);
  
  const handleChange = (e: any) => {
    props.setGlobalFilterValue(e.target.value);
  };

  const handleEnterKey = (e: any) => {
    if (props.searchByEnterKey && e.key === 'Enter') {
        searchFilter();
    }
  };

  const searchFilter = () => {
    if (props.globalFilterValue !== null) {
      if (props.remote) {
        props.onSearchRemote && props.onSearchRemote(props.globalFilterValue);
      } else {
        props.onSearch(props.globalFilterValue);
      }
    }
  };

  return (
    <InputGroup>
      <InputGroup.Prepend>
        <InputGroup.Text>
          <Tooltips placement="auto" icon={faSearch} rootBoundary={props.rootBoundary}>
            <b>Saisissez un texte pour mettre à jour le tableau.</b>
          </Tooltips>
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        id="input-search"
        name="input-search"
        placeholder={props.searchBarPlaceholder || "Recherche"}
        type="text"
        onChange={handleChange}
        value={(props.globalFilterValue !== null ? props.globalFilterValue : '')}
        onKeyDown={handleEnterKey}
        autoComplete="off"
        autoFocus
      />
    </InputGroup>
  );
};

/**
 * Composant DataTable permettant l'affichage de données dans un tableau Bootstrap enrichi de Tris et de Filtres
 *
 * ## Usage
 * ```tsx
 * <SocleDataTable columns={columns} data={data} />
 * ```
 */
const SocleDataTable = forwardRef(
  (
    {
      keyField,
      columns,
      data,
      remote,
      loadingOptions,
      paginationOptions,
      selectableRows,
      selectRow,
      rowEvents,
      noDataIndication,
      rootBoundary,
      defaultSorted,
      pagination,
      filter,
      toolkitOptions,
      hideSearchBar,
      hideColumnSelector,
      sizeControl,
      searchByEnterKey = true,
      searchByManualEntry = false,
      searchLatencyMs = 1000,
      searchMinLength = 3,
      columnSelectorByGroupCategory,
      columnSelectorSearchable,
      onSearch,
      overrideFilterIcon,
      smallSpace,
      columnSelectorRenderer,
      componentInteractSearchColumnToggle,
      searchBarPlaceholder,
      highlightRowOnHover,
      ...rest
    }: ISWDataTableProps,
    ref?: any
  ) => {
    const filterRef: any = useRef<any>([]);
    const [customColumns, setCustomColumns] = useState<IPersistedSocleColumnDataTable[]>(
      []
    );
    const [visibilityUpdatedColumns, setVisibilityUpdatedColumns] = useState<IPersistedSocleColumnDataTable[]>([]);
    const [filtredData, setFiltredData] = useState<any[]>([]);
    const [filtersValues, setFiltersValues] = useState<any>({});
    const [show, setShow] = useState<any>({});
    const [showColumnSelector, setShowColumnSelector] = useState<boolean>(false);
    const [showSizePerPageSelector, setShowSizePerPageSelector] = useState<boolean>(false);
    const [globalFilterValue, setGlobalFilterValue] = useState<any>(null);

    useEffect(() => {
      setFiltredData(data);
    }, [data]);

    const onToggle = (col: string, isOpen: boolean) => {
      setShow((prevState: any) => ({
        ...prevState,
        [col]: isOpen,
      }));
    };

    const onToggleColumnSelector = (isOpen: boolean) => {
      setShowColumnSelector(isOpen);
    };

    const onToggleSizePerPageSelector = (isOpen: boolean) => {
      setShowSizePerPageSelector(isOpen);
    };

    const filterByDefault = (
      filterVal: any,
      column: IPersistedSocleColumnDataTable | any
    ) => {
      if (remote && column?.onFilter) {
        return column?.onFilter(filterVal);
      }

      if (
        filterVal &&
        ((typeof filterVal === 'object' && filterVal.length > 0) ||
          (typeof filterVal === 'string' && filterVal !== ''))
      ) {
        const newFiltersValues: any = { ...filtersValues };
        newFiltersValues[column?.dataField] = filterVal;

        const filters: any[] = Object.entries(newFiltersValues);
        let newFiltredData: any[] = [...data];

        filters.forEach(([key, value]) => {
          if (typeof value === 'object') {
            const val: any[] = Array.from(new Set(value.flatMap((a: any) => a?.hasOwnProperty("value") ? a?.value : value)));
            newFiltredData = newFiltredData.filter((d: any) =>
              val
                .map((v: any) => normalizeText(v).toLowerCase())
                .includes(normalizeText(parseKey(d, key)).toLowerCase())
            );
          } else {
            newFiltredData = newFiltredData.filter((d: any) =>
              normalizeText(parseKey(d, key))
                .toLowerCase()
                .includes(normalizeText(value).toLowerCase())
            );
          }
        });
        return newFiltredData;
      }
      return data;
    };

    const filterRenderer = (onFilter: any, column: any, col: IPersistedSocleColumnDataTable, showPP: any): any => {
      const onFilterHandler = (filter: any) => {
        col.isFilter = !filter || filter?.length === 0 ? false : true;
        col.activeFilters = filter;
        onFilter(filter);
      };
      if (column?.dataList) {
        return <SWStringSelectFilter
          ref={(instance: any) => {
            filterRef.current[column.dataField] = instance;
          }}
          show={showPP}
          column={column}
          onFilter={onFilterHandler}
          onToggle={onToggle}
        />;
      }
      if (column?.labelValueDataList) {
        return <SWLabelValueSelectFilter
          ref={(instance: any) => {
            filterRef.current[column.dataField] = instance;
          }}
          show={showPP}
          column={column}
          onFilter={onFilterHandler}
          onToggle={onToggle}
        />;
      }
      return <SWDefaultFilter
        ref={(instance: any) => {
          filterRef.current[column.dataField] = instance;
        }}
        show={showPP}
        column={column}
        onFilter={onFilterHandler}
        onToggle={onToggle}
      />;
    };

    const sortCaret = (order: any, columnType?: string) => {
      if (!order)
        return (
          <span className={`table-column-sorter-inner${smallSpace ? " icon-sm" : ''}`}>
            <span className="sorticon">
            <SvgDefaultSort />
            </span>
          </span>
        );
      else if (order === 'asc')
        return (
          <span className={`table-column-sorter-inner${smallSpace ? " icon-sm" : ''}`}>
            <span className="sorticon">
              {columnType === "number" ? (
                <SvgAscSortNumber />
              ) : (
                <SvgAscSortString />
              )}
            </span>
          </span>
        );
      else if (order === 'desc')
        return (
          <span className={`table-column-sorter-inner${smallSpace ? " icon-sm" : ''}`}>
            <span className="sorticon">
              {columnType === "number" ? (
                <SvgDescSortNumber />
              ) : (
                <SvgDescSortString />
              )}
            </span>
          </span>
        );
      return null;
    };

    const updateDatatableColumns = (columns: IPersistedSocleColumnDataTable[], updatedColumns?: IPersistedSocleColumnDataTable[]) => {
      if (columns && columns.length > 0) {
        const cols = columns?.map((col: IPersistedSocleColumnDataTable) => {
          let isHidden;
          const column = updatedColumns?.find(c => c?.dataField === col?.dataField);
          if (column) {
            isHidden = column?.hidden;
          } else {
            isHidden = col?.hidden;
          }

          const getHeaderClasses = (column: ColumnDescription<any, any>, colIndex: number) => {
            const headerClasses = col?.headerClasses;
            if (typeof headerClasses === 'function') {
              return `${headerClasses(column, colIndex)}${smallSpace ? " title-sm" : ""}`;
            } else if (typeof headerClasses === 'string') {
              return `${headerClasses}${smallSpace ? " title-sm" : ""}`;
            } else if (smallSpace) {
              return "title-sm";
            } else {
              return "";
            }
          };

          return {
            ...col,
            dataField: col.dataField,
            text: col.text,
            hidden: isHidden,
            activeFilters: col?.activeFilters,
            infoBulleText: col?.infoBulleText,
            // On ajoute title-sm si smallSpace est true
            headerClasses: getHeaderClasses,
            headerFormatter: (column: any, _: number, components: any) => (
              <SWHeaderFormatter
                column={column}
                components={components}
                filter={col?.defaultFilter}
                show={show}
                rootBoundary={rootBoundary}
                overrideFilterIcon={overrideFilterIcon}
                smallSpace={smallSpace}
                onToggle={onToggle}
              />
            ),
            filter:
              col?.filter ||
              textFilter({
                onFilter: (value: any) => filterByDefault(value, col),
                getFilter: (f: any) => {
                  col.resetFilter = f;
                },
              }),

            filterRenderer: (onFilter: any, column: any): any =>
              col?.filterRenderer || filterRenderer(onFilter, column, col, show),
            sortCaret: col?.sortCaret || ((order: any) => sortCaret(order, col?.columnType)),

            dataList: col?.dataList,
          }
        });
        setCustomColumns(cols);
      }
    };

    useEffect(() => {
      updateDatatableColumns(columns, visibilityUpdatedColumns);
    }, [data, columns, show, visibilityUpdatedColumns]);

    useImperativeHandle(ref, () => ({
      clearAllFilters: () => {
        clearAllFilters();
      },
      clearGlobalFilter: () => {
        clearGlobalFilter();
      },
      getFilters: () => {
        let filters = {};
        columns.forEach((col: any) => {
          if (col?.defaultFilter) {
            filters[col.dataField] = col?.activeFilters;
          }
        });
        return {
          columnFilters: filters,
          globalFilter: globalFilterValue
        };
      },
    }));

    const clearAllFilters = () => {
      columns.forEach((col: any) => {
        if (col?.resetFilter) col?.resetFilter();
        if (filterRef?.current[col.dataField])
          filterRef?.current[col.dataField]?.onReset();
      });
    };

    const clearGlobalFilter = () => {
      setGlobalFilterValue(null);
    };


    const options: PaginationOptions = {
      ...paginationOptions,
      sizePerPage: paginationOptions?.sizePerPage || 5,
      showTotal:
        paginationOptions?.showTotal ||
        (!loadingOptions?.active && filtredData.length > 0 ? true : false),
      alwaysShowAllBtns:
        paginationOptions?.alwaysShowAllBtns ||
        (remote ? true : paginationOptions?.sizePerPage !== filtredData.length),
      sizePerPageList: paginationOptions?.sizePerPageList || [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
      paginationTotalRenderer:
        paginationOptions?.paginationTotalRenderer || customTotal,
      pageListRenderer: paginationOptions?.pageListRenderer || pageListRenderer,
      sizePerPageRenderer: (options: any) => <SWSizePerPage options={options.options} currSizePerPage={options.currSizePerPage} show={showSizePerPageSelector} rootBoundary={rootBoundary} onSizePerPageChange={options.onSizePerPageChange} onToggle={onToggleSizePerPageSelector} />,
    };

    const getDataSize = () => {
      if(remote === undefined || remote === false || (typeof remote !== 'boolean' && remote?.pagination === false)) {
        return filtredData?.length;
      } else {
        return paginationOptions?.totalSize;
      }
    }

    const customSelectionHeaderIcon = (options: { mode: string; checked: boolean; indeterminate: boolean; }) => {
      if (options.mode === 'checkbox') {
        return <IconCheckBox checked={getDataSize() !== 0 && selectRow?.selected?.length === (getDataSize() - selectRow?.nonSelectable?.length)} strokeColor="#9E9E9E" width='20' height='20' />;
      }
      if (options.mode === 'radio') {
        return <IconRadio checked={options.checked} strokeColor="#9E9E9E" />;
      }
    };

    const customSelectionIcon = (options: { checked: boolean; disabled: boolean; mode: string; rowIndex: number; }) => {
      if (options.mode === 'checkbox') {
        return <IconCheckBox checked={options.checked} strokeColor="#9E9E9E" width='20' height='20' />;
      }
      if (options.mode === 'radio') {
        return <IconRadio checked={options.checked} strokeColor="#9E9E9E" />;
      }
      return <></>;
    };

    const selectRowProps: SelectRowProps<any> = {
      mode: selectRow?.mode || 'checkbox',
      bgColor: selectRow?.bgColor || 'rgba(227, 227, 220, .2)',
      selected: selectRow?.selected,
      onSelect: selectRow?.onSelect,
      onSelectAll: selectRow?.onSelectAll,
      selectionRenderer: customSelectionIcon,
      selectionHeaderRenderer: customSelectionHeaderIcon,
      nonSelectableClasses: 'hide-checkbox',
      ...selectRow,
    };

    const customNoDataIndication = () => {
      return (
        <div className="table-empty">
          <div className="table-empty-image">
            <FontAwesomeIcon icon={faBoxOpen} size="10x" />
          </div>
          <div className="table-empty-description">Aucune donnée trouvée</div>
        </div>
      );
    };

    const afterFilter = (_: any, filters: any) => {
      const newFiltersValues: any = { ...filtersValues };
      const newFilters: any = {};
      const filterTmp: any[] = Object.entries(filters);

      filterTmp.forEach(([key, value]) => {
        newFilters[key] = value.filterVal;
      });

      if (JSON.stringify(newFilters) !== JSON.stringify(newFiltersValues)) {
        setFiltersValues(newFilters);
      }
    };

    const columnToggle = (column: any, toggle: any) => {
      const cols = [...visibilityUpdatedColumns];
      updateColumnToggle(cols, column, toggle);
      setVisibilityUpdatedColumns(cols);
    };

    const columnsToggle = (columns: any[], toggle: any) => {
      const cols = [...visibilityUpdatedColumns];
      columns.forEach(column => {
        updateColumnToggle(cols, column, toggle);
      });
      setVisibilityUpdatedColumns(cols);
    };

    const updateColumnToggle = (cols: any[], column: any, toggle: any) => {
      const col = cols.find(c => c.dataField === column?.dataField);
      if (col) {
        col.hidden = !toggle;
      } else {
        column.hidden = !toggle;
        cols.push(column);
      }
    };

    const getColumnToggleColOffset = () => {
      if (componentInteractSearchColumnToggle) {
        return "";
      }
      return hideSearchBar ? "offset-sm-8" : "offset-sm-4";
    };

    const paginationClassName = paginationOptions?.hideSizePerPage ? 'pagination-sans-size-per-page' : 'pagination-avec-size-per-page';
    const modifiedRowEvents = {
      onMouseEnter: (e: any) => {
        const tableRow = e.target.closest('tr');
        if (tableRow) {
          tableRow.classList.add('highlightRow');
        }
      },
      onMouseLeave: (e: any) => {
        const tableRow = e.target.closest('tr');
        if (tableRow) {
          tableRow.classList.remove('highlightRow');
        }
      },
      ...rowEvents
    }

    return (
      <div className={`SocleDataTable ${paginationClassName}`} id="datatable-container">
        {customColumns.length > 0 && (
          <ToolkitProvider
            keyField={keyField || 'id'}
            columns={customColumns}
            data={filtredData}
            search
            columnToggle
            {...toolkitOptions}
          >
            {(props: any) => (
              <>
                {(!hideSearchBar || !hideColumnSelector || componentInteractSearchColumnToggle) && (
                  <Row>
                    {!hideSearchBar &&
                      <Col className="pl-0">
                        <CustomSearch
                          {...props.searchProps}
                          globalFilterValue={globalFilterValue}
                          setGlobalFilterValue={setGlobalFilterValue}
                          remote={remote}
                          rootBoundary={rootBoundary}
                          onSearchRemote={onSearch}
                          searchBarPlaceholder={searchBarPlaceholder}
                          searchByEnterKey={searchByEnterKey}
                          searchByManualEntry={searchByManualEntry}
                          searchLatencyMs={searchLatencyMs}
                          searchMinLength={searchMinLength}
                        />
                      </Col>
                    }
                    {componentInteractSearchColumnToggle &&
                      <Col className={`d-flex justify-content-end ${hideSearchBar ? "offset-sm-4" : ""} ${hideColumnSelector ? "pr-0" : ""}`}>
                        {componentInteractSearchColumnToggle(
                          {
                            global: {
                              rootBoundary: rootBoundary,
                              remote: remote,
                            },
                            search: {
                              innerProps: {
                                searchText: globalFilterValue,
                                onSearch: props.searchProps.onSearch,
                                onClear: props.searchProps.onClear
                              },
                              onSearchRemote: onSearch,
                            },
                            columnToggle: {
                              innerProps: { ...props.columnToggleProps },
                              showColumnSelector: showColumnSelector,
                              onToggleColumnSelector: onToggleColumnSelector,
                              columnToggle: columnToggle,
                              columnsToggle: columnsToggle,
                            },
                          }
                        )}
                      </Col>
                    }
                    {!hideColumnSelector && (
                      <Col className={`pr-0 d-flex justify-content-end ${getColumnToggleColOffset()}`}>
                        {columnSelectorRenderer ?
                          columnSelectorRenderer({ ...props.columnToggleProps }, showColumnSelector, rootBoundary, onToggleColumnSelector, columnToggle, columnsToggle)
                          : <SWColumnToggle
                            {...props.columnToggleProps}
                            show={showColumnSelector}
                            columnSelectorByGroupCategory={columnSelectorByGroupCategory}
                            columnSelectorSearchable={columnSelectorSearchable}
                            rootBoundary={rootBoundary}
                            onToggle={onToggleColumnSelector}
                            columnToggle={columnToggle}
                            columnsToggle={columnsToggle}
                          />
                        }
                      </Col>
                    )}
                    <hr />
                  </Row>
                )}

                <LoadingOverlay
                  active={loadingOptions?.active}
                  text={loadingOptions?.text || 'Chargement...'}
                  {...loadingOptions}
                >
                  <BootstrapTable
                    wrapperClasses={`table-responsive ${(!hideSearchBar || !hideColumnSelector) ? "mt-3" : ""} ${sizeControl ? "table-size-control" : ""}`}
                    {...props.baseProps}
                    remote={remote}
                    filter={filter || filterFactory({ afterFilter })}
                    pagination={
                      pagination ||
                      paginationFactory(options)
                    }
                    selectRow={selectableRows ? selectRowProps : undefined}
                    rowEvents={highlightRowOnHover ? modifiedRowEvents : rowEvents}
                    noDataIndication={
                      !loadingOptions?.active &&
                      (noDataIndication || customNoDataIndication)
                    }
                    defaultSorted={defaultSorted}
                    {...rest}
                  />
                </LoadingOverlay>
              </>
            )}
          </ToolkitProvider>
        )}
      </div>
    );
  }
);

export default React.memo(SocleDataTable);
